import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { activate } from "../../commons/Api";
import { logout } from "../../commons/AuthTokenUtils";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";

const Activation = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setLoading(true);
      activate(token)
        .then((response) => {
          logout(() => navigate("/notice/" + response.data));
        })
        .catch((error) => {
          logout(() => navigate("/notice/Activation failed!"));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/error");
    }
  }, [token]);
  return loading ? (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <LaSpinner variant="light" sm />
    </Box>
  ) : (
    <></>
  );
};

export default Activation;
