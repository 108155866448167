import { Typography } from "@mui/material";
import { COLOR_BRED } from "../../commons/LaColors";

const InlineErrorMessage = (props: { errorMessage: string[] }) => {
  return (
    <>
      {props.errorMessage.map((item, index) => {
        return (
          <Typography
            key={index}
            align="left"
            component="h1"
            variant="body1"
            color={COLOR_BRED}
          >
            {item}
          </Typography>
        );
      })}
    </>
  );
};

export default InlineErrorMessage;
