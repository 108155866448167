import PageTitle from "../components/pageTitle/PageTitle.component";
import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <div className="max-width row-sm">
        <PageTitle title="Terms and conditions" />
        <p>
          <p>
            <i>Last updated: 01.03.2024</i>
          </p>
          <h5>1. Terms of Service</h5>
          These Terms of Service ("Terms") govern your use of our platform and
          any services offered through it. By accessing or using our platform,
          you agree to be bound by these Terms. If you do not agree to these
          Terms, please do not use our platform.
        </p>
        <p>
          <h5>2. Definitions and Interpretation</h5>
          In these Terms, unless the context otherwise requires:
          <ul>
            <li>
              “Platform” refers to the website or application through which our
              services are provided.
            </li>
            <li>
              “User” refers to any individual accessing or using our platform.
              This includes visitors and registered users, who interact with our
              platform in any way.
            </li>
            <li>
              “Content” refers to definitions and terms both submitted by users
              to the platform.
            </li>
            <li>
              “Term” refers to the headings or titles under which the definition
              is written.
            </li>
            <li>
              “Definition” refers to the definitions that are given for each
              term or header. It clarifies the topic, gives background, and
              presents thorough details related to the heading.
            </li>
          </ul>
        </p>
        <p>
          <h5>3. User Contributions</h5>
          <ol>
            <li>
              Submission Requirements: Users are encouraged to contribute
              content to Lapedia, including but not limited to, definitions and
              suggestions of terms.
            </li>
            <li>
              Review Process
              <ul>
                <li>
                  All user-contributed content i.e Terms and Definitions
                  undergoes a review process to ensure accuracy, relevance, and
                  adherence to Lapedia's guidelines.
                </li>
                <li>
                  We refrain from altering definitions because our role is not
                  to impose our own perspectives. Instead, we maintain a
                  standard by removing definitions that violate laws, employ
                  offensive language, or contain inappropriate wording. It's
                  crucial to uphold consistency to foster user trust and ensure
                  their contributions align with our guidelines. However, if
                  users wish to introduce a new term, they can simply submit it,
                  and if necessary, we can adjust the formatting, such as
                  capitalization, and refine the term to ensure clarity and
                  conformity with our style guide, without altering the
                  definitions. Once we make such changes, we'll notify the users
                  about the update. If a user disagrees with the modification,
                  they have the right to remove their submission. Continuous use
                  indicates acceptance of the change. Imagine a scenario where a
                  user submits a new term, "internet safety," but it's written
                  in lowercase letters. Upon review, we acknowledge the term's
                  relevance but notice the formatting doesn't adhere to our
                  style guide, which prefers capitalization for headings.
                </li>
              </ul>
            </li>
            <li>
              Approval and Publication
              <ul>
                <li>
                  At Lapedia, we are dedicated to providing our community with
                  accurate and timely content, and we place a high importance on
                  user feedback. Our team of experts puts users' submitted
                  content through a thorough screening procedure. The terms will
                  be authorized and made available on our platform so that other
                  users can benefit from them if they satisfy our quality
                  standards and are judged appropriate for posting.
                </li>
                <li>
                  At Lapedia, we strive to review and approve user submissions
                  in a timely manner. However, the speed at which submissions
                  are processed for approval and publication may fluctuate based
                  on factors such as the volume of submissions received and the
                  availability of our moderation team. During periods of high
                  submission activity or limited moderator availability, there
                  may be delays in the approval and publication process. We
                  appreciate your patience and understanding as we work to
                  ensure that all contributions are reviewed thoroughly and
                  accurately before being published on our platform.
                </li>
              </ul>
            </li>
          </ol>
        </p>
        <p>
          <h5>4. Content Ownership and Rights</h5>
          <ul>
            <li>
              Users maintain ownership rights to the content they contribute to
              the platform. This means that they continue to hold the
              intellectual property rights associated with their submissions.
              However, by submitting content to our platform, users grant us a
              non- exclusive license to utilize and distribute their
              contributions for platform-related purposes. This license allows
              us to display the content to other users, make necessary edits to
              the terms for accuracy or formatting, and share the content across
              our platform as needed. It's important to note that while users
              retain ownership of their contributions, this license ensures that
              we have the necessary permissions to effectively manage and
              present user-generated content within the framework of our
              platform.
            </li>
            <li>
              The platform operates under the understanding that users are
              responsible for the content they contribute. While every effort is
              made to ensure compliance with copyright laws and guidelines, the
              platform cannot guarantee the absolute absence of copyrighted
              material submitted by users. Users are expected to adhere to
              copyright laws and regulations when submitting content. In the
              event that copyrighted material is posted without authorization,
              the platform shall not be held liable for such infringement. It is
              the responsibility of users to ensure that their contributions do
              not violate any copyrights, and they agree to indemnify and hold
              harmless the platform, its affiliates, and personnel from any
              claims, damages, or legal actions arising from copyright
              violations associated with their submissions. The platform
              reserves the right to remove any content believed to infringe upon
              copyright laws without prior notice. Additionally, users are
              encouraged to report any suspected copyright violations promptly.
            </li>
            <li>
              Lapedia condemns the unauthorized copying and redistribution of
              material posted on our platform. While we encourage users to share
              content from our website responsibly, we require that proper
              citation of our website be provided. For example, if a user wishes
              to share an insightful article from Lapedia on social media or
              another platform, they are obligated to correctly cite the
              definition, ensuring clear attribution to Lapedia or by including
              a direct link to the definition they are referencing. Any
              reproduction or distribution of our material without the proper
              citation constitutes a violation of our copyright policies. We
              reserve the right to take appropriate legal action against
              individuals or platforms found to be engaging in such practices.
              We urge all users to respect intellectual property rights and to
              adhere to our terms of use, which strictly prohibit the
              unauthorized copying, redistribution, or modification of content
              without prior consent and citation from Lapedia.
            </li>
          </ul>
        </p>
        <p>
          <h5>5. Account Confidentiality</h5>
          Users are responsible for safeguarding their account credentials,
          including usernames, passwords, and any other login information. It's
          important to choose strong and unique passwords, and to avoid sharing
          account credentials with anyone else. Users should also take
          precautions to protect their account from unauthorized access, such as
          logging out after each session, especially when using shared or public
          devices.
        </p>
        <p>
          <h5>6. Activity Monitoring</h5>
          Users are responsible for monitoring and controlling the activities
          performed under their account. This includes being aware of any
          actions taken on the platform using their account credentials, such as
          submitting content. If users notice any suspicious or unauthorized
          activity on their account, they should report it to the platform
          provider immediately and take steps to secure their account.
        </p>
        <p>
          <h5>7. Privacy Policy</h5>
          Our Privacy Policy governs the collection, use, and disclosure of
          personal information provided by users and is incorporated herein by
          reference.
        </p>
        <p>
          <h5>8. User Conduct and Prohibited Activities</h5>
          At Lapedia, we foster a welcoming and respectful community where users
          can contribute. To maintain the integrity of our platform and ensure a
          positive experience for all users, we expect everyone to adhere to the
          following guidelines:
          <ul>
            <li>
              Compliance with Laws and Regulations: Users must comply with all
              applicable laws, regulations, and legal requirements when using
              our platform.
            </li>
            <li>
              Respectful Behavior: Treat moderators, and administrators with
              respect and courtesy. Do not engage in any form of harassment,
              discrimination, or hate speech based on race, ethnicity, gender,
              religion, sexual orientation, or any other characteristic.
            </li>
            <li>
              Civil Discourse: Engage in constructive and civil discourse. Avoid
              personal attacks, insults, or inflammatory language.
            </li>
            <li>
              Accuracy and Integrity: Provide accurate and truthful information
              in your contributions. Do not intentionally spread false or
              misleading content.
            </li>
            <li>
              Intellectual Property Rights: Respect the intellectual property
              rights of others. Do not upload or share content that infringes
              upon copyrights, trademarks, or other proprietary rights.
            </li>
            <li>
              Prohibited Content: Do not submit or distribute content that is
              unlawful, obscene, defamatory, libelous, threatening, or otherwise
              objectionable. This includes but is not limited to content that
              promotes violence, illegal activities, or harmful behavior.
            </li>
            <li>
              Spam and Solicitation: Do not engage in spamming or solicitation.
              Refrain from posting excessive promotional content,
              advertisements, or links to external websites without prior
              authorization.
            </li>
            <li>
              Abuse of Platform Features: Do not misuse or abuse platform
              features.
            </li>
            <li>
              Compliance with Moderation: Follow instructions provided by
              moderators and administrators. Do not attempt to circumvent
              moderation actions or sanctions.
            </li>
            <li>
              Reporting Violations: Report any violations of these guidelines or
              suspicious activities to our moderation team for review and
              action.
            </li>
          </ul>
          By using our platform, users agree to abide by these guidelines and
          acknowledge that failure to do so may result in disciplinary action,
          including but not limited to account suspension or termination.
          Lapedia reserves the right to remove any content that violates these
          guidelines and takes appropriate measures to enforce compliance.
        </p>
        <p>
          <h5>9. Limitation of Liability</h5>
          This clause outlines the extent to which the platform can be held
          responsible for damages incurred by users. It states that the platform
          shall not be held liable for any direct, indirect, incidental,
          special, or consequential damages arising from the use of the
          platform. In other words, users acknowledge and accept that they use
          the platform at their own risk and that the platform will not be held
          accountable for any damages or losses incurred.
        </p>
        <p>
          <h5>10. Indemnification</h5>
          This clause requires users to indemnify and hold harmless the
          platform, its affiliates, and their respective officers, directors,
          employees, and agents from any claims, losses, damages, liabilities,
          and expenses. In simpler terms, users agree to take responsibility for
          any legal claims or actions brought against the platform due to their
          actions or content contributions. By agreeing to indemnify the
          platform, users essentially promise to cover the costs or damages
          incurred by the platform as a result of their conduct.
        </p>
        <p>
          <h5>11. Disclaimer of Warranties</h5>
          The platform is provided "as is" and "as available," without any
          warranties or representations of any kind. In simpler terms, the
          platform makes no guarantees or assurances regarding its performance,
          reliability, or suitability for any particular purpose. Users
          acknowledge and accept that the platform may have limitations,
          defects, or vulnerabilities, and that the platform cannot guarantee
          uninterrupted or error-free operation. By using the platform, users
          agree to do so at their own risk and understand that the platform will
          not be held responsible for any damages or losses incurred as a result
          of its use. Additionally, this disclaimer emphasizes that any
          information or content provided on the platform is for informational
          purposes only and should not be relied upon as professional advice or
          guidance.
        </p>
        <p>
          <h5>12. Age Restriction</h5>
          We maintain a strict policy that requires all users accessing our
          platform to be a minimum of 18 years of age. This age restriction is
          in place to ensure compliance with legal regulations and to create a
          safe and appropriate environment for our users. By setting this
          minimum age requirement, we aim to protect the privacy and well-being
          of individuals who interact with our platform. Additionally, this
          policy helps to safeguard against potential risks associated with
          underage users accessing content that may not be suitable for them. We
          are committed to upholding this standard and expect all users to
          adhere to this policy when using our services.
        </p>
        <p>
          <h5>13. Governing Law and Jurisdiction</h5>
          This platform and these Terms of Service shall be governed by and
          construed in accordance with the laws of Germany, without regard to
          its conflict of law provisions. Any dispute arising out of or relating
          to these Terms of Service or the use of this platform shall be
          exclusively resolved by the courts of Frankfurt, Germany. Users hereby
          consent to the exclusive jurisdiction and venue of such courts and
          waive any objection based on inconvenient forum or other grounds.
        </p>
        <p>
          <h5>14. Severability</h5>
          If any provision of these Terms of Service is determined to be invalid
          or unenforceable by a court of competent jurisdiction, such invalidity
          or unenforceability shall not affect the validity or enforceability of
          any other provision of these Terms of Service, which shall remain in
          full force and effect.
        </p>
        <p>
          <h5>15. No Waiver</h5>
          The failure of the platform to enforce any provision of this shall not
          be construed as a waiver of such provision or any other provision
          herein.
        </p>
        <p>
          <h5>16. Headings</h5>
          The headings in these Terms of Service are for convenience of
          reference only and shall not affect the interpretation of this.
        </p>
        <p>
          <h5>17. Force Majeure</h5>
          Neither party shall be liable or responsible to the other party, nor
          be deemed to have defaulted under or breached these Terms of Service,
          for any failure or delay in fulfilling or performing any term of these
          Terms of Service when and to the extent such failure or delay is
          caused by or results from acts or circumstances beyond the reasonable
          control of the affected party, including, without limitation, acts of
          God, flood, fire, earthquake, explosion, governmental actions, war,
          invasion, or hostilities (whether war is declared or not), terrorist
          threats or acts, riot, or other civil unrest, national emergency,
          revolution, insurrection, epidemic, lockouts, strikes or other labor
          disputes (whether or not relating to either party's workforce), or
          restraints or delays affecting carriers or inability or delay in
          obtaining supplies of adequate or suitable materials, materials, or
          telecommunication breakdown or power outage.
        </p>
        <p>
          <b>
            <i>
              By accessing and using this website, users are deemed to have
              read, understood, and agreed to the terms and conditions outlined
              herein. If users do not agree with these terms and conditions,
              they should refrain from using this website. Continued use of the
              website constitutes acceptance of these terms and conditions.
            </i>
          </b>
        </p>
        <br />
      </div>
    </>
  );
};

export default TermsAndConditions;
