import axios from "axios";
import { BACKEND_URL } from "./LaConstants";
import { getAuthToken } from "./AuthTokenUtils";

const token = getAuthToken();

export const laAxios = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    Authorization: token ? "Bearer " + token : undefined,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
