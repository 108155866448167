import { useState } from "react";
import LaButton from "../button/LaButton.component";
import classes from "./CopyToClipboard.module.css";
import { MdCheck } from "react-icons/md";
import { IoCopy } from "react-icons/io5";

type CopyToClipboardProps = {
  textToCopy: string;
};

const CopyToClipboard = (props: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy = (): void => {
    navigator.clipboard.writeText(props.textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3600);
    });
  };

  return (
    <>
      {isCopied ? (
        <div className={classes.isCopied}>
          <MdCheck size={20} />
          <div>Link copied</div>
        </div>
      ) : (
        <LaButton onClick={() => copy()} laStyle="btn-icon">
          <IoCopy size={20} />
        </LaButton>
      )}
    </>
  );
};

export default CopyToClipboard;
