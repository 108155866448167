import Response_Notification from "../../types/responses/Response.Notification.type";
import { Card } from "react-bootstrap";
import classes from "./NotificationCard.module.css";
import { epochToDateTime } from "../../commons/LaUtils";

const NotificationCard = (props: {
  notification: Response_Notification;
  showAll?: boolean;
}) => {
  return (
    <>
      <div>
        <Card className={classes.notification}>
          <Card.Body>
            {/*
            <Card.Title>
              <div className={classes.notificationTitle}>
                {epochToDateTime(props.notification.createdOn)}
              </div>
            </Card.Title>
  */}
            <>
              <div>{props.showAll && "Id: " + props.notification.id}</div>
              {props.showAll && props.notification.userId && (
                <div>{"userId: " + props.notification.userId}</div>
              )}
              <div>{props.showAll && "Seen: " + props.notification.seen}</div>
              <div>{props.notification.message}</div>{" "}
              <div className={classes.notificationDate}>
                {epochToDateTime(props.notification.createdOn)}
              </div>
            </>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default NotificationCard;
