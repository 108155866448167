import React from "react";
import { Link } from "react-router-dom";
import classes from "./MobileFooter.module.css";

interface IFooterItem {
  title: string;
  redTitle?: boolean;
  href: string;
  LOGO: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  //ocument: boolean | undefined;
}

const StickyFooterItem = ({
  title,
  redTitle,
  href,
  LOGO,
  isActive,
  onClick, //reloadDocument,
}: IFooterItem) => {
  return (
    <div
      className={`col text-center my-auto ${
        isActive ? classes.active : classes.link_item
      }`}
    >
      <Link
        //reloadDocument={reloadDocument}
        to={href}
        onClick={onClick}
        className="nav-link"
      >
        <div
          className={`mb-1 mt-1  h-[22px] max-w-[23px] mx-auto ${classes.logo}`}
        >
          {LOGO}
        </div>
        <h6 className={` ${classes.title} ${redTitle && classes.adminTitle}`}>
          {title}
        </h6>
      </Link>
    </div>
  );
};

export default StickyFooterItem;
