import classes from "./Footer.module.css";
import LaButton from "../../components/button/LaButton.component";
import MobileFooter from "./MobileFooter";
import { useNavigate } from "react-router";

const Footer = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.container}>
          <LaButton
            laStyle="btn-text-gray"
            onClick={() => navigate("/termsandconditions")}
          >
            Terms and Conditions
          </LaButton>
          <LaButton
            laStyle="btn-text-gray"
            onClick={() => navigate("/privacypolicy")}
          >
            Privacy Policy
          </LaButton>
          <LaButton
            laStyle="btn-text-gray"
            onClick={() => navigate("/legalnotice")}
          >
            Legal Notice
          </LaButton>
        </div>
      </footer>
      <MobileFooter
        unclickAllMobileButtons={props.unclickAllMobileButtons}
        handleMenuButtonClick={props.handleMenuButtonClick}
        showHomeMobileButton={props.showHomeMobileButton}
        showRecentlyAddedMobileButton={props.showRecentlyAddedMobileButton}
        showAboutMobileButton={props.showAboutMobileButton}
        showAdminSettingsMobileButton={props.showAdminSettingsMobileButton}
      />
    </>
  );
};
export default Footer;
