import { TbArrowBigDownFilled } from "react-icons/tb";
import { COLOR_BNAVY, COLOR_BYELLOW } from "../../../commons/LaColors";

const DownvoteClicked = () => {
  return (
    <TbArrowBigDownFilled
      size={34}
      color={COLOR_BYELLOW}
      style={{
        padding: "7px",
        background: COLOR_BNAVY,
        borderRadius: "50%",
        border: `1.2px solid ${COLOR_BNAVY}`,
      }}
    />
  );
};

export default DownvoteClicked;
