import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router";
import Response_SearchResult from "../../types/responses/Response.SearchResult.type";
import { debounce } from "lodash";
import StyledTextField from "../../components/mui/StyledTextField";
import SearchResult from "../../types/SearchResult.type";
import { Paper } from "@mui/material";
import { searchAsYouType } from "../../commons/Api";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { stripHtml, toPrettyTermName } from "../../commons/LaUtils";
import { HTMLAttributes } from "react";
import classes from "./Searchbox.module.css";
import LaButton from "../../components/button/LaButton.component";
import { BiSearch } from "react-icons/bi";
import { COLOR_BMEDIUMGRAY } from "../../commons/LaColors";

interface SearchBoxProps {
  onCloseHandler?: () => void;
}

// managing state
export default function SearchBox({ onCloseHandler }: SearchBoxProps) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  //const [value, setValue] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(open && options.length === 0);
  const navigate = useNavigate();
  const [showAllResultButton, setShowAllResultButton] = useState<boolean>(true);

  const OtherResults = (props: HTMLAttributes<HTMLElement>) => {
    return (
      <div>
        <Paper {...props}>
          {props.children}
          {!!options.length && showAllResultButton ? (
            <div className="px-4 my-3">
              <LaButton
                type="button"
                fullWidth
                onMouseDown={(event) => {
                  event.preventDefault();
                  navigate("/search/" + inputValue);
                  setShowAllResultButton(false);
                  setOpen(false);
                  onCloseHandler && onCloseHandler();
                }}
                laStyle="btn-navy"
              >
                Display all results
              </LaButton>
            </div>
          ) : null}
        </Paper>
      </div>
    );
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  async function getResults(value: string) {
    setLoading(true);
    searchAsYouType(value, 10)
      .then((response) => {
        var searchdata: Response_SearchResult = response.data;
        var searchResults: SearchResult[] | undefined = [];
        searchdata.terms.forEach(
          (t) =>
            searchResults?.push({
              id: t.id,
              termName: t.name,
              matchedText: t.name,
              elementType: "Terms",
              path: "/term/" + t.id + "/" + toPrettyTermName(t.name),
            })
        );
        searchdata.definitions.forEach((d) => {
          searchResults?.push({
            // TODO-adi: use matchedText
            id: d.definition.id,
            termName: d.definition.termName,
            matchedText: stripHtml(d.definition.content),
            elementType: "Definitions",
            path: "/definition/" + d.definition.id,
          });
        });

        setOptions(searchResults);
      })
      .catch((error) => {
        // blank
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onChangeHandler = async (value: any) => {
    if (value === null) {
      return;
    } else {
      await setOptions([]);
      await setInputValue("");
      navigate(value?.path);
    }
  };

  const debouncedSearch = React.useRef(
    debounce(async (value) => {
      await changeOptionBaseOnValue(value);
    }, 2)
  ).current;

  const changeOptionBaseOnValue = (value: string) => {
    if (value.length >= 2) {
      getResults(value);
      setShowAllResultButton(true);
    } else {
      setOptions([]);
    }
  };

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      //
    }
  };

  // Rendering our parameters on the DOM
  return (
    <>
      <div
        className={
          !show
            ? classes.searchbox
            : classes.searchbox + " " + classes.showsearchbox
        }
      >
        <Autocomplete
          className={classes.AutocompleteSearchbox}
          PaperComponent={OtherResults}
          freeSolo
          noOptionsText={"Empty results"}
          size="small"
          id="termsanddefinitions"
          onChange={(event, newInputValue) => onChangeHandler(newInputValue)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            debouncedSearch(newInputValue);
          }}
          onKeyDown={keyPress}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
            onCloseHandler && onCloseHandler();
          }}
          isOptionEqualToValue={(option, value) =>
            option.matchedText === value.matchedText
          }
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }

            if (option.elementType === "Definitions") {
              return (
                "(" +
                option.termName +
                ") " +
                option.matchedText.substring(0, 20) +
                "..."
              );
            } else {
              return option.termName;
            }
          }}
          options={options}
          loading={loading}
          groupBy={(option) => option.elementType}
          renderOption={(props, option) => {
            return (
              <div
                // className="border-top"
                style={{
                  fontSize: 14,
                }}
                key={option.id}
              >
                <li {...props} key={option.id}>
                  {option.elementType === "Definitions"
                    ? "(" +
                      option.termName +
                      ") " +
                      option.matchedText.substring(0, 20) +
                      "..."
                    : option.termName}
                </li>
              </div>
            );
          }}
          renderGroup={(params) => {
            return (
              <div className="px-4">
                <div
                  className=" text-secondary px-3 sticky-top bg-white"
                  style={{ top: "-8px", fontSize: "14px" }}
                >
                  <h6 className="border-bottom py-2">{params.group}</h6>
                </div>
                <div>{params.children}</div>
              </div>
            );
          }}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <StyledTextField
              // sx={onCloseHandler && { border: 'none', "& fieldset": { border: 'none' }, }}
              {...params}
              autoFocus={!!onCloseHandler}
              placeholder="Search Lapedia"
              itemID={params.id}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <BiSearch color={COLOR_BMEDIUMGRAY} size={24} />
                  </InputAdornment>
                ),
                //disableUnderline: true,
                style: {
                  fontSize: 14,
                  border: "none",
                  paddingTop: 7,
                  paddingBottom: 7,
                },
              }}
            />
          )}
        />
      </div>
      <div className={classes.btnmobile}>
        <button className={classes.btnMobileNav} onClick={() => setShow(!show)}>
          <SearchIcon
            className={"icon " + classes.icon}
            sx={{ color: "action.active", mr: 1, my: 0.5 }}
          />
        </button>
      </div>
    </>
  );
}
