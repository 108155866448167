/**
 * Copied from https://github.com/mui/material-ui/blob/v5.12.2/docs/data/material/getting-started/templates/sign-up/SignUp.tsx
 */
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  COLOR_BDARKGRAY,
  COLOR_BMEDIUMGRAY,
  COLOR_BNAVY,
  COLOR_BWHITE,
} from "../../commons/LaColors";
import StyledTextField from "../../components/mui/StyledTextField";
import LaButton from "../../components/button/LaButton.component";
import { forgotPassword } from "../../commons/Api";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import {
  analyzeAxiosError,
  handleErrorRouting,
  isValidEmail,
} from "../../commons/LaUtils";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import InlineErrorMessage from "../../components/alerts/InlineErrorMessage.component";
import { BiSolidEnvelope } from "react-icons/bi";
import { useNavigate } from "react-router";

const theme = createTheme();

const ForgotPassword = () => {
  let WRONG_EMAIL_MESSAGE = "Invalid email";

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string[]>([]);
  const [successfulRequestSubmitted, setSuccessfulRequestSubmitted] =
    useState<boolean>();
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setErrorMessage([]);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");

    if (typeof email !== "string" || !isValidEmail(email)) {
      setErrorMessage([WRONG_EMAIL_MESSAGE]);
      setLoading(false);
      return;
    }

    forgotPassword(email)
      .then((response) => {
        setSuccessfulRequestSubmitted(response.data);
      })
      .catch((error) => {
        let errorCode = analyzeAxiosError(error);
        if (errorCode === -1 || errorCode >= 500) {
          handleErrorRouting(error, (url: string) => navigate(url));
        } else {
          setErrorMessage([WRONG_EMAIL_MESSAGE]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return !successfulRequestSubmitted ? (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          background: COLOR_BWHITE,
          borderRadius: "6px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: { xs: "10px", md: 4 },
            paddingBottom: { xs: "10px", md: 4 },
            paddingTop: { xs: "10px", md: 4 },
            paddingX: { xs: "10px", md: 6 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: COLOR_BNAVY }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color={COLOR_BDARKGRAY}>
            Please enter your email address
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, minWidth: "100%" }}
          >
            <InlineErrorMessage errorMessage={errorMessage} />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                <>
                  <BiSolidEnvelope
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Email Address
                </>
              }
              name="email"
              autoComplete="email"
            />

            <LaButton
              type="submit"
              fullWidth
              onClick={() => handleSubmit}
              laStyle="btn-navy"
              customClassName="my-3  py-12"
            >
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <LaSpinner variant="light" sm />
                </Box>
              ) : (
                "Send"
              )}
            </LaButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  ) : (
    <Alert className="max-width">
      Your request has been sent successfully! Please check your email.
    </Alert>
  );
};

export default ForgotPassword;
