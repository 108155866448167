import { ChangeEvent } from "react";
import StyledTextField from "../mui/StyledTextField";

const TextFieldWithButton = (props: {
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  button: any;
}) => {
  return (
    <StyledTextField
      size="small"
      sx={{ width: { xs: "100%", md: "100%" } }}
      id={props.id}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        endAdornment: props.button,
        style: {
          fontSize: 14,
          padding: "8px",
        },
      }}
    />
  );
};

export default TextFieldWithButton;
