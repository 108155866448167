import VerticalMenu from "../../layout/verticalMenu/VerticalMenu.component";
import VerticalMenuSectionItems from "../../types/VerticalMenuSectionItems.type";
import { COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { BiSolidEnvelope, BiSolidLock, BiSolidUser } from "react-icons/bi";
import { BsCaretRightFill } from "react-icons/bs";

const Preferences = () => {
  let section1: VerticalMenuSectionItems = {
    items: [
      {
        to: "/user/preferences/profile/",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} /> <p className="my-auto ms-2">Profile</p>
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/user/preferences/changepassword/",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidLock size={22} />{" "}
            <p className="my-auto ms-2">Change password</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/user/preferences/changeemail/",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidEnvelope size={22} />{" "}
            <p className="my-auto ms-2">Change email</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/user/preferences/deleteaccount/",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">Delete account</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/user/preferences/definitions",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">My definitions</p>{" "}
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
      {
        to: "/user/preferences/personaldata",
        children: (
          <div className="d-flex justify-content-start">
            <BiSolidUser size={22} />{" "}
            <p className="my-auto ms-2">My personal data</p>
            <BsCaretRightFill
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          </div>
        ),
      },
    ],
  };

  return (
    <div className="max-width">
      <VerticalMenu sections={[section1]} title="User Settings" />
    </div>
  );
};
export default Preferences;
