import { getInitials } from "../../commons/LaUtils";
import classes from "./PageTitle.module.css";

const PageTitle = (props: {
  title: string;
  subTitle?: any;
  sortBy?: any;
  insidePage?: boolean;
  backButton?: any;
  avatar?: boolean;
}) => {
  return (
    <>
      <div
        className={`d-flex justify-content-between flex-md-row pb-2 ${
          props.backButton !== undefined ? " flex-row" : " flex-column "
        } `}
      >
        <div>
          <div className="d-flex justify-content-start gap-2">
            {props.avatar && (
              <div className={classes.avatar}>{getInitials(props.title)}</div>
            )}
            <div>
              <div
                className={`${classes.pageTitle} ${
                  props.insidePage ? classes.insidePage : " "
                }`}
              >
                {props.title}
              </div>
              <div>{props.subTitle !== undefined && props.subTitle}</div>
            </div>
          </div>
        </div>
        {props.sortBy !== undefined && props.sortBy}
        {props.backButton !== undefined && props.backButton}
      </div>
      {<div className={classes.titleMarginBottom}>{""}</div>}
    </>
  );
};

export default PageTitle;
