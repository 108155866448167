import { useState } from "react";
import { Grid } from "@mui/material";
import LaButton from "../../components/button/LaButton.component";
import classes from "./VotingGrid.module.css";
import { updateVote } from "../../commons/Api";
import { handleErrorRouting } from "../../commons/LaUtils";
import { useNavigate } from "react-router";
import { ATTR_REDIRECT } from "../../commons/LaConstants";
import DownvoteClicked from "./buttons/DownvoteClicked";
import DownvoteNotClicked from "./buttons/DownvoteNotClicked";
import UpvoteClicked from "./buttons/UpvoteClicked";
import UpvoteNotClicked from "./buttons/UpvoteNotClicked";

type VotingGridProps = {
  id: string;
  votingScore: number;
  selfVote?: boolean;
  authorUpvoted: boolean;
  authorDownvoted: boolean;
  updateCount: number;
};

const VotingGrid = (props: VotingGridProps) => {
  const [votingScore, setVotingScore] = useState<number | undefined>(
    props.votingScore,
  );
  const [authorUpvoted, setAuthorUpvoted] = useState<boolean>(
    props.authorUpvoted,
  );
  const [authorDownvoted, setAuthorDownvoted] = useState<boolean>(
    props.authorDownvoted,
  );
  const [updateCount, setUpdateCount] = useState<number>(props.updateCount);

  const navigate = useNavigate();

  const voteHandler = (up: boolean) => {
    //Order by voting
    if (
      (authorUpvoted || authorDownvoted) &&
      !window.confirm("Would you like to delete your vote?")
    ) {
      return;
    }

    let add = true;
    if ((up && authorUpvoted) || (!up && authorDownvoted)) {
      add = false;
    }

    updateVote(props.id, add, up)
      .then((response) => {
        setVotingScore(response.data.votingScore);
        if (up) {
          setAuthorUpvoted(!authorUpvoted);
        } else {
          setAuthorDownvoted(!authorDownvoted);
        }
      })
      .catch((error) => {
        handleErrorRouting(
          error,
          (url: string) => navigate(url),
          "/user/login?" + ATTR_REDIRECT + "=/definition/" + props.id,
        );
      });
  };

  if (updateCount !== props.updateCount) {
    setVotingScore(props.votingScore);
    setAuthorUpvoted(props.authorUpvoted);
    setAuthorDownvoted(props.authorDownvoted);
    setUpdateCount(props.updateCount);
  }

  return (
    <span className={classes.votingGrid}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ maxWidth: "104px" }}
      >
        <Grid item xs={4} sx={{ justifyContent: "center" }}>
          <LaButton
            onClick={() => voteHandler(true)}
            disabled={authorDownvoted || props.selfVote}
            laStyle={authorUpvoted ? "btn-voting-clicked" : "btn-voting"}
            children={authorUpvoted ? <UpvoteClicked /> : <UpvoteNotClicked />}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.votingScore}>{votingScore}</div>
        </Grid>
        <Grid item xs={4}>
          <LaButton
            onClick={() => voteHandler(false)}
            disabled={authorUpvoted || props.selfVote}
            laStyle={authorDownvoted ? "btn-voting-clicked" : "btn-voting"}
            children={
              authorDownvoted ? <DownvoteClicked /> : <DownvoteNotClicked />
            }
          />
        </Grid>
      </Grid>
    </span>
  );
};

export default VotingGrid;
