import classes from "./misc.module.css";
import notFoundIllustration from "../assets/notFoundIllustration.svg";
import maintenanceIllustration from "../assets/maintenanceIllustration.svg";

export enum NoMatch_Type {
  exception,
  forbidden,
  maintenance,
  notFound,
}

const NoMatch = (props: { type: NoMatch_Type }) => {
  let illustration;
  let text;
  if (props.type === NoMatch_Type.maintenance) {
    illustration = maintenanceIllustration;
    text = "We will be right back! The site is under maintenance";
  } else {
    illustration = notFoundIllustration;
    text = "This page does not exist or is no longer available";
  }

  return (
    <>
      <div
        className={`${classes.noMatchBackground} d-flex flex-column justify-content-center align-items-center`}
      >
        <img
          src={illustration}
          className={`px-md-0 img-fluid`}
          alt={"illustration"}
        />
        <div className={`${classes.noMatchTopMargin} ${classes.noMatchText}`}>
          {text}
        </div>
      </div>
    </>
  );
};

export default NoMatch;
