import { ChangeEvent, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DefinitionCardList, {
  DefinitionCardList_Type,
} from "../../../definitions/definitionCardList/DefinitionCardList";
import classes from "./DisplayById.module.css";
import TextFieldWithButton from "../../../components/textFieldWithSearchButton/TextFieldWithButton";
import ElementCard from "../../../components/elementCard/ElementCard.component";
import { getDefinition_Admin, getTerm_Admin } from "../../../commons/Api";
import TermTree from "../../../terms/termTree/TermTree";
import DefinitionCard from "../../../definitions/definitionCard/DefinitionCard";
import NotificationCardList, {
  NotificationCardList_Type,
} from "../../../notification/notificationCardList/NotificationCardList";
import { handleErrorRouting } from "../../../commons/LaUtils";
import UserProfile from "../../preferences/userProfile/UserProfile";
import { Box } from "@mui/material";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import { BsCaretLeftFill } from "react-icons/bs";
import { COLOR_BMEDIUMGRAY } from "../../../commons/LaColors";
import { useNavigate } from "react-router";

export enum DisplayById_Type {
  term,
  termTree,
  definition,
  authorProfile,
  authorDefinitions,
  authorNotifications,
}

const DisplayById = (props: { type: DisplayById_Type }) => {
  const [id, setId] = useState<string>("");
  const [elementToDisplay, setElementToDisplay] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const onClick = () => {
    if (props.type === DisplayById_Type.term) {
      setLoading(true);
      getTerm_Admin(id)
        .then((response) => {
          const term = response.data;
          setElementToDisplay(
            <ElementCard
              termId={term.id}
              termName={term.name}
              termCreatedOn={term.createdOn}
              termLastUpdatedOn={term.lastUpdatedOn}
              termTotalDefinitionCount={term.totalDefinitionCount}
              termState={term.state + ""}
            />,
          );
        })
        .catch((error) => {
          handleErrorRouting(error, (url: string) => navigate(url));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (props.type === DisplayById_Type.termTree) {
      setElementToDisplay(
        <TermTree propTermId={id} blockUrlCorrection={true} displayById />,
      );
    }
    if (props.type === DisplayById_Type.definition) {
      setLoading(true);
      getDefinition_Admin(id)
        .then((response) => {
          setElementToDisplay(<DefinitionCard definition={response.data} />);
        })
        .catch((error) => {
          handleErrorRouting(error, (url: string) => navigate(url));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (props.type === DisplayById_Type.authorProfile) {
      setElementToDisplay(<UserProfile id={id} />);
    }
    if (props.type === DisplayById_Type.authorDefinitions) {
      setElementToDisplay(
        <DefinitionCardList
          id={id}
          type={DefinitionCardList_Type.authorDefinitionsAdmin}
        />,
      );
    }
    if (props.type === DisplayById_Type.authorNotifications) {
      setElementToDisplay(
        <NotificationCardList
          id={id}
          type={NotificationCardList_Type.authorNotifications}
        />,
      );
    }
  };

  const SearchButton = () => (
    <IconButton onClick={onClick}>
      <SearchIcon />
    </IconButton>
  );

  var placeholder = "";
  switch (props.type) {
    case DisplayById_Type.term:
    case DisplayById_Type.termTree:
      placeholder = "Term id";
      break;
    case DisplayById_Type.definition:
      placeholder = "Definition id";
      break;
    case DisplayById_Type.authorProfile:
    case DisplayById_Type.authorDefinitions:
    case DisplayById_Type.authorNotifications:
      placeholder = "Author id";
      break;
  }

  // TODO-adi: css of StyledTextField are redundant from the SearchBox

  return (
    <>
      <div
        className="d-flex justify-content-end mb-2"
        style={{ marginTop: "-11px" }}
      >
        <BsCaretLeftFill
          onClick={() => navigate("/admin/settings")}
          className="ms-auto  d-md-none"
          size={24}
          color={COLOR_BMEDIUMGRAY}
        />
      </div>
      <div className={classes.idPage}>
        <div className={classes.displayByIdTextField}>
          <TextFieldWithButton
            id="some-id"
            placeholder={placeholder}
            value={id}
            onChange={onChange}
            button={<SearchButton />}
          />
        </div>
        {}
        {loading ? (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <LaSpinner variant="light" sm />
          </Box>
        ) : (
          <>
            {elementToDisplay && <div className="p-3">{elementToDisplay}</div>}
          </>
        )}
      </div>
    </>
  );
};

export default DisplayById;
