import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { inputClasses } from "@mui/material/Input";
import { styled } from "@mui/material/styles";

import { COLOR_BBLUE, COLOR_BLIGHTGRAY } from "../../commons/LaColors";

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: COLOR_BLIGHTGRAY,
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: COLOR_BBLUE,
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: COLOR_BBLUE,
    },
  //[`& .${outlinedInputClasses.input}`]: {
  //  color: COLOR_BBLUE,
  //},
  //[`&:hover .${outlinedInputClasses.input}`]: {
  //  color: COLOR_BBLUE,
  //},
  //[`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
  //  {
  //    color: COLOR_BDARKGRAY,
  //  },
  [`& .${inputLabelClasses.outlined}`]: {
    fontSize: 14,
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: COLOR_BBLUE,
    fontSize: 14,
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: COLOR_BBLUE,
    fontSize: 14,
  },
  [`& .${inputClasses.root}`]: {
    fontSize: 14,
  },
  [`&:hover .${inputClasses.root}`]: {
    color: COLOR_BBLUE,
    fontSize: 14,
  },
  [`& .${inputClasses.root}.${inputClasses.focused}`]: {
    color: COLOR_BBLUE,
    fontSize: 14,
  },
});

export default StyledTextField;
