import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import classes from "./DeleteAccount.module.css";
import PageTitle from "../../../components/pageTitle/PageTitle.component";
import { deleteUserAccount, getUserProfile } from "../../../commons/Api";
import LaButton from "../../../components/button/LaButton.component";
import { Alert } from "react-bootstrap";
import { logout } from "../../../commons/AuthTokenUtils";
import {
  analyzeAxiosError,
  handleErrorRouting,
  isValidEmail,
} from "../../../commons/LaUtils";
import { Typography } from "@mui/material";
import LaModal from "../../../components/modal/LaModal.component";
import { Form } from "react-bootstrap";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import { isValidPassword } from "../../../commons/LaUtils";
import InlineErrorMessage from "../../../components/alerts/InlineErrorMessage.component";
import { BsCaretLeftFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { COLOR_BMEDIUMGRAY } from "../../../commons/LaColors";

const DeleteAccount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [typedEmail, setTypedEmail] = useState<string>("");
  const [typedPassword, setTypedPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const navigate = useNavigate();

  const MISTYPED_EMAIL_ERROR_MESSAGE =
    "The email you entered does not match your email.";

  const WRONG_PASSWORD_ERROR_MESSAGE = "Wrong password";

  const message = (
    <Typography align="left" component="h1" variant="body1" fontWeight="500">
      This step is a permanent deletion of your account. It implies deleting all
      the definitions associated with the account.
    </Typography>
  );

  useEffect(() => {
    setErrorMessage([]);
    setLoading(true);
    getUserProfile()
      .then((response) => {
        setEmail(response.data.email);
        setUserId(response.data.id);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setErrorMessage([]);

    let updatedErrorMessage = [];

    if (
      !isValidEmail(typedEmail) ||
      !email ||
      email.toLowerCase() !== typedEmail.toLowerCase()
    ) {
      updatedErrorMessage.push(MISTYPED_EMAIL_ERROR_MESSAGE);
    }

    if (!isValidPassword(typedPassword)) {
      updatedErrorMessage.push(WRONG_PASSWORD_ERROR_MESSAGE);
    }

    if (updatedErrorMessage.length !== 0) {
      setErrorMessage(updatedErrorMessage);
      return;
    }

    setLoading(true);
    deleteUserAccount(typedPassword)
      .then((response) => {
        setIsDeleted(true);
        logout(() => navigate("/"));
      })
      .catch((error) => {
        let errorCode = analyzeAxiosError(error);
        if (errorCode === -1 || errorCode >= 500) {
          handleErrorRouting(error, (url: string) => navigate(url));
        } else {
          setErrorMessage([WRONG_PASSWORD_ERROR_MESSAGE]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={classes.deletePage}>
        <PageTitle
          title="Delete account"
          insidePage
          backButton={
            <BsCaretLeftFill
              onClick={() => navigate("/user/preferences")}
              className="ms-auto d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          }
        />
        {loading ? (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <LaSpinner />
          </Box>
        ) : isDeleted ? (
          <div>
            <Alert>Account is successfully deleted!</Alert>
          </div>
        ) : (
          <>
            <div className={classes.bottomMargin}>
              {message}
              <div className={classes.buttonTopMargin}>
                <LaButton
                  type="submit"
                  onClick={() => setShow(true)}
                  laStyle="btn-red"
                  customClassName="my-3"
                >
                  Delete account permanently
                </LaButton>
              </div>
            </div>
            <LaModal
              show={show}
              onHide={() => setShow(false)}
              body={
                <div className={classes.newterm}>
                  <div className="pt-4 my-1">
                    <Form.Group>
                      <Typography
                        align="center"
                        component="h4"
                        variant="body1"
                        fontWeight="400"
                        sx={{
                          mt: 0,
                          mb: "37px",
                          pl: "15px",
                          backgroundColor: "#e9ecef",
                          padding: "15px",
                          borderRadius: "5px",
                        }}
                      >
                        {"Delete account with user id: " +
                          userId +
                          ", and email: " +
                          email +
                          "."}
                      </Typography>
                      {/* <Form.Label>Definition</Form.Label> */}
                      <Typography
                        align="left"
                        component="h1"
                        variant="body1"
                        fontWeight="500"
                        sx={{ mt: 2, mb: "37px", pl: "15px" }}
                      >
                        {message}
                      </Typography>
                      <Typography
                        align="left"
                        component="h1"
                        variant="body1"
                        fontWeight="500"
                        sx={{ mt: 2, mb: "37px", pl: "15px" }}
                      >
                        If you are sure, please type your email and press the
                        button.
                      </Typography>
                      <InlineErrorMessage errorMessage={errorMessage} />
                      <Form.Control
                        type="text"
                        className={`${classes.newtermTop}`}
                        placeholder="Email"
                        value={typedEmail}
                        onChange={(e) => {
                          setTypedEmail(e.target.value);
                        }}
                      />
                      <Form.Control
                        type="password"
                        className={`${classes.newtermTop} `}
                        placeholder="Password"
                        value={typedPassword}
                        onChange={(e) => {
                          setTypedPassword(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <LaButton
                      onClick={() => handleSubmit()}
                      fullWidth={true}
                      laStyle={"btn-red"}
                      children={"Delete account permanently"}
                      customClassName="mb-3 mt-2"
                    />
                  </div>
                </div>
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default DeleteAccount;
