import PageTitle from "../components/pageTitle/PageTitle.component";
import LaLink from "../components/link/LaLink.component";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <div className="max-width row-sm">
        <PageTitle title="Privacy policy" />
        <p>
          <p>
            <i>Last updated: 01.03.2024</i>
          </p>
          <h5>1. Introduction</h5>
          Welcome to Lapedia ("we," "our," or "us"). As stewards of your
          privacy, we've crafted this Privacy Policy to provide you with clarity
          on how we handle your personal information when you engage with our
          website. This document outlines the processes involved in the
          collection, utilization, disclosure, and protection of your personal
          data. By utilizing our website, you signify your acceptance of the
          terms and conditions set forth in this Privacy Policy. Your continued
          use of our website indicates your agreement with these terms. If you
          do not agree with any aspect of this Privacy Policy, we kindly ask
          that you refrain from accessing or utilizing our website. Your privacy
          and trust are of utmost importance to us, and we are committed to
          ensuring that your personal information is handled with care and in
          accordance with this Privacy Policy.
        </p>
        <p>
          <h5>2. Information We Collect</h5>
          Information You Provide: When you interact with our website, we may
          collect personal information that you willingly provide. This can
          include details such as your name, email address, and any other
          information you choose to share with us. It's important to note that
          this information is gathered on a voluntary basis, meaning you have
          the discretion to decide whether or not to provide it. However, please
          be aware that certain features or functionalities of our website may
          require you to provide certain personal information in order to fully
          utilize them. This collected information falls under the category of
          personal data as defined by Article 4 of the General Data Protection
          Regulation (GDPR). As such, we are committed to handling this data in
          accordance with the guidelines and principles outlined in the GDPR,
          ensuring that your privacy rights are protected and respected at all
          times.
        </p>
        <p>
          <h5>3. How We Use Information</h5>
          <ol>
            <li>
              Improving Our Website: Your thoughts and contributions are super
              important to us. They help us make Lapedia better for everyone. By
              seeing what you do on the site, we can figure out what's working
              well and what could be better. This helps us fix any problems and
              make Lapedia even more helpful and fun to use for everyone.
            </li>
            <li>
              Sharing of Content: We want Lapedia to be a place where people can
              upload and read the content, and share what they know. This makes
              Lapedia a lively and friendly community where everyone can learn
              and share their knowledge on different topics.
            </li>
          </ol>
        </p>
        <p>
          <h5>4. How We Share Information</h5>
          We may share your personal information in the following circumstances:
          <ol>
            <li>
              With Your Consent: Your privacy matters to us, and we respect your
              control over your personal information. If there's a specific
              reason we need to share your information with someone else, we'll
              always ask for your permission first. This means we'll only share
              your information when you explicitly agree to it.
            </li>
            <li>
              With Service Providers: To provide you with the best possible
              service, we sometimes need to work with other companies or
              individuals who help us operate our website, run our business, or
              assist in servicing you. These are our trusted service providers,
              and they may need access to your information to perform their
              duties effectively. Rest assured, we only share the information
              they need, and we make sure they're committed to keeping your data
              safe and secure.
            </li>
            <li>
              For Legal Purposes: While we prioritize your privacy, there are
              rare instances where we may be required by law to disclose your
              information. This could happen if we receive a subpoena, court
              order, or other governmental request that legally obligates us to
              share your information. In such cases, we'll carefully review the
              request and only disclose your information if it's necessary to
              comply with the law and protect our rights, your rights, or the
              rights of others.
            </li>
          </ol>
        </p>
        <p>
          <h5>5. Protection of Personal Information</h5>
          Your privacy is important to us, so we make sure to take steps to keep
          your personal information safe. We use reasonable measures to protect
          it from being accessed, shared, changed, or deleted by people who
          shouldn't have it. However, it's important to understand that no
          method of transmitting data over the internet or wireless networks can
          be completely foolproof. While we do our best to keep your information
          secure, we can't promise it will always be 100% safe.
        </p>
        <p>
          <h5>6. User Rights Under GDPR</h5>
          <ol>
            <li>
              Subject Access Request (Article 15): You have the right to request
              information about any of your personal data that we are
              processing. This includes confirmation of whether we're processing
              your data and a copy of your personal data.
            </li>
            <li>
              Right to Rectification (Article 16): You have the right to request
              the correction of any inaccurate data that we hold about you.
            </li>
            <li>
              Right to Erasure (Article 17): You have the right to request the
              deletion of your personal data, also known as the "right to be
              forgotten."
            </li>
            <li>
              Conditions Applicable to Child's Consent (Article 8): If we
              process the personal data of a child under the age of 16 for
              "information society services" and rely on consent as our lawful
              basis, we will obtain consent from their parent or carer.
            </li>
            <li>
              Right to Restrict Processing (Article 18): You have the right to
              request that we refrain from certain processing activities with
              your personal data.
            </li>
            <li>
              Right to Data Portability (Article 20): You have the right to
              request a copy of any of your personal data that we possess, in a
              commonly used and machine-readable format.
            </li>
            <li>
              Right to Object (Article 21): You have the right to object to the
              processing of your data, including direct marketing activities.
            </li>
          </ol>
        </p>
        <p>
          <h5>7. Cookies</h5>
          Cookies are small pieces of data stored on your browser that help
          websites remember your preferences and activities. Some websites use
          cookies to track your behavior and personalize your experience.
          However, we don't use cookies on our website. We believe in respecting
          your privacy and ensuring a transparent online experience. So, you can
          browse our website without worrying about cookies tracking your
          activities.
        </p>
        <p>
          <h5>8. Changes to this Privacy Policy</h5>
          Sometimes, we might need to update our Privacy Policy. If we make any
          changes, we'll let you know by posting the new Privacy Policy on this
          page. This way, you can always check back here to see if anything has
          changed.
        </p>
        <p>
          <h5>9. Contact Information</h5>
          If you have any questions or concerns regarding this Privacy Policy or
          wish to exercise any of your rights under GDPR, please contact us at{" "}
          <LaLink to={"mailto:contact@lapedia.net"}>
            contact@lapedia.net.
          </LaLink>
        </p>
        <br />
      </div>
    </>
  );
};

export default PrivacyPolicy;
