import classes from "./PreviousNext.module.css";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { COLOR_BBLUE } from "../../commons/LaColors";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

const PreviousNextWithPaging = (props: {
  searchParamPage: string | undefined | null;
  nextPageExists: boolean;
  url: string;
}) => {
  let searchParamPageAsInt =
    props.searchParamPage === undefined || props.searchParamPage === null
      ? 1 // search parameter "page" starts from page "1"
      : +props.searchParamPage;

  let navigate = useNavigate();

  return (
    <div className={`${classes.previousNext} `}>
      {searchParamPageAsInt > 1 && (
        <div className={classes.previous}>
          <GoChevronLeft
            color={COLOR_BBLUE}
            className="cursor-pointer"
            size={44}
            onClick={() => {
              if (searchParamPageAsInt === 2) {
                navigate(props.url);
              } else {
                navigate({
                  pathname: props.url,
                  search: createSearchParams({
                    page: "" + (searchParamPageAsInt - 1),
                  }).toString(),
                });
              }
            }}
          />
        </div>
      )}
      {props.nextPageExists ? (
        <div className={classes.next}>
          <GoChevronRight
            color={COLOR_BBLUE}
            size={44}
            className="cursor-pointer"
            onClick={() => {
              navigate({
                pathname: props.url,
                search: createSearchParams({
                  page: "" + (searchParamPageAsInt + 1),
                }).toString(),
              });
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
export default PreviousNextWithPaging;
