import { Dispatch, SetStateAction, useState } from "react";
import { approveTerm_Admin, deleteTermTree_Admin } from "../../commons/Api";
import LaButton from "../../components/button/LaButton.component";
import {
  handleErrorRouting,
  stateToString,
  verifyTermNameAndAlert,
} from "../../commons/LaUtils";
import State_Type from "../../types/State.type";
import LaModal from "../../components/modal/LaModal.component";
import { Form } from "react-bootstrap";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import classes from "./ApproveRejectTermElement.module.css";
import { useNavigate } from "react-router";

const ApproveRejectTermElement = (props: {
  setApprovedSuccessfully: Dispatch<SetStateAction<boolean>>;
  setRejectedSuccessfully: Dispatch<SetStateAction<boolean>>;
  termId: string;
  termName: string;
}) => {
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [showApprove, setShowApprove] = useState<boolean>(false);
  const [termName, setName] = useState<string>(props.termName);

  const navigate = useNavigate();

  const approveTermHandler = () => {
    if (!verifyTermNameAndAlert(termName)) {
      return;
    }
    setLoadingApprove(true);
    approveTerm_Admin({ id: props.termId, termName: termName })
      .then((response) => {
        if (
          stateToString(response.data.term.state) ===
          State_Type[State_Type.approved]
        ) {
          props.setApprovedSuccessfully(true);
        }
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoadingApprove(false);
      });
  };

  const rejectTermHandler = () => {
    setLoadingReject(true);
    deleteTermTree_Admin(props.termId)
      .then((response) => {
        if (
          stateToString(response.data.state) === State_Type[State_Type.deleted]
        ) {
          props.setRejectedSuccessfully(true);
        }
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoadingReject(false);
      });
  };

  return (
    <div>
      <div className={classes.approveReject}>
        <LaButton
          onClick={() => setShowApprove(true)}
          children={
            loadingApprove ? (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <LaSpinner variant="light" sm />
              </Box>
            ) : (
              "Approve"
            )
          }
          laStyle={"btn-yellow"}
          customClassName="py-2"
        />

        <LaButton
          onClick={() => rejectTermHandler()}
          children={
            loadingReject ? (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <LaSpinner variant="light" sm />
              </Box>
            ) : (
              "Reject"
            )
          }
          laStyle="btn-red"
          customClassName="py-2"
        />
      </div>
      <LaModal
        show={showApprove}
        onHide={() => setShowApprove(false)}
        // title={"Approve term: " + props.termId}
        // body={
        //   <Form.Control
        //     type="text"
        //     placeholder="Term name"
        //     value={termName}
        //     onChange={(e) => {
        //       setName(e.target.value);
        //     }}
        //   />
        // }
        // footer={
        //   <LaButton
        //     onClick={() => approveTermHandler()}
        //     fullWidth={true}
        //     laStyle={"btn-green"}
        //     children={"Approve"}
        //   />
        // }
        body={
          <div className={classes.newterm}>
            <div className="pt-4">
              <Form.Group>
                {/* <InlineErrorMessage errorMessage={errorMessage} /> */}
                {/* <Form.Label>Term</Form.Label> */}
                <Form.Control
                  type="text"
                  disabled
                  className={` ${classes.marginBottom} border shadow-none`}
                  placeholder="Add term name.."
                  value={"Approve term: " + props.termId}
                  readOnly
                />
                <Form.Control
                  type="text"
                  className={classes.newtermTop}
                  placeholder="Term name"
                  value={termName}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                {/* <Form.Label>Definition</Form.Label> */}
                <Form.Text></Form.Text>
                {/* <ReactQuill
                  value={updatedContent}
                  style={{height: "300px"}}
                  placeholder="Add definition.."
                  onChange={(value) => {
                    setUpdatedContent(value);
                  }}
                  className={`quill-inline ${classes.quillContainer}`}
                /> */}
              </Form.Group>
            </div>
            <div>
              <LaButton
                onClick={() => approveTermHandler()}
                fullWidth={true}
                laStyle={"btn-navy"}
                customClassName="mb-3 mt-2"
                children={"Approve"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ApproveRejectTermElement;
