import classes from "./misc.module.css";
import logoGrayScaleSvg from "../assets/logoGrayScaleSvg.svg";
import LaButton from "../components/button/LaButton.component";
import searchSvg from "../assets/search.svg";
import { useEffect } from "react";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <div
        className={`${classes.about_header} d-flex flex-column justify-content-center align-items-center`}
      >
        <img
          src={logoGrayScaleSvg}
          className="px-md-0 img-fluid "
          alt="Lapedia logo"
        />
      </div>
      <div className={`${classes.about_content} bg-white`}>
        <div className="max-width row col-12">
          <div className="col-md-6 px-0">
            <h1>About Lapedia</h1>
            <p>
              Lapedia is a trustworthy platform for knowledge providers,
              learners, and researchers to share authentic information supported
              by thorough epistemological research in all fields of knowledge.
            </p>
            <p>
              Our mission is to revolutionize the traditional encyclopedia model
              in a new style, where the tremendous knowledge hidden in
              everyone's brains is collected and carefully presented. Each
              talent in this galaxy owns their space in Lapedia and can express
              their knowledge smoothly through simple words.
            </p>
            <p>
              Lapedia offers a platform where users can not only contribute
              definitions but also engage in a collaborative process of
              validation through upvoting and downvoting. Through this
              interaction, readers can access content that is not only credible
              but also refined through community consensus.
            </p>
            <p>
              Founded by Dr. Oday Jubran, a dedicated researcher with a number
              of scientific publications and years of experience in the IT
              industry, Lapedia brings together a global community of talents
              committed to this vision.
            </p>
            <p>
              Join Lapedia today to share your expertise, gain visibility, and
              be part of a transformative journey towards a brighter tomorrow.
            </p>
            <LaButton
              onClick={() => {
                window.location.href = "mailto:contact@lapedia.net";
              }}
              children={<>Contact us</>}
              laStyle="btn-yellow"
              customClassName={`${classes.btn} mt-md-3 mt-2 mb-3`}
            />
          </div>
          <div className="col-md-6 d-flex flex-column justify-items-center align-items-center pt-md-0 pt-5">
            <div
              className={`${classes.illustration} my-auto ms-auto me-auto me-md-0 `}
            >
              <img
                src={searchSvg}
                className="px-md-0 px-2 mx-auto img-fluid"
                alt="Search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
