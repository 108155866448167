import { Alert } from "react-bootstrap";
import { useParams } from "react-router";

const Notice = () => {
  const { notice } = useParams();

  return <Alert className="max-width">{notice}</Alert>;
};

export default Notice;
