/**
 * Copied from https://github.com/mui/material-ui/blob/v5.12.2/docs/data/material/getting-started/templates/sign-up/SignUp.tsx
 */
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  COLOR_BMEDIUMGRAY,
  COLOR_BNAVY,
  COLOR_BWHITE,
} from "../../commons/LaColors";
import StyledTextField from "../../components/mui/StyledTextField";
import LaButton from "../../components/button/LaButton.component";
import LaLink from "../../components/link/LaLink.component";
import classes from "./SignUp.module.css";
import { register } from "../../commons/Api";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import {
  handleErrorRouting,
  isValidDisplayName,
  isValidEmail,
  isValidPassword,
} from "../../commons/LaUtils";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import InlineErrorMessage from "../../components/alerts/InlineErrorMessage.component";
import { BiSolidEnvelope, BiSolidLock, BiSolidUser } from "react-icons/bi";
import { useNavigate } from "react-router";

const theme = createTheme();

const SignUp = () => {
  const [loading, setLoading] = React.useState(false);
  const [invalidInput, setInvalidInput] = React.useState<string[]>([]);

  let INVALID_EMAIL_MESSAGE = "Invalid email";
  let INVALID_PASSWORD_MESSAGE = "Password should have at least 6 characters";
  let INVALID_DISPLAYNAME_MESSAGE =
    "Display name should have 2 to 70 characters";

  const [successfulRequestSubmitted, setSuccessfulRequestSubmitted] =
    useState<boolean>();

  const [alertMessage, setAlertMessage] = useState<string>("");

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setInvalidInput([]);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");
    let displayName = data.get("displayName");

    let updatedInvalidInput: string[] = [];

    if (typeof email !== "string" || !isValidEmail(email)) {
      updatedInvalidInput.push(INVALID_EMAIL_MESSAGE);
    }
    if (
      !password ||
      typeof password !== "string" ||
      !isValidPassword(password)
    ) {
      updatedInvalidInput.push(INVALID_PASSWORD_MESSAGE);
    }

    if (
      !displayName ||
      typeof displayName !== "string" ||
      !isValidDisplayName(displayName)
    ) {
      updatedInvalidInput.push(INVALID_DISPLAYNAME_MESSAGE);
    }

    if (updatedInvalidInput.length > 0) {
      setInvalidInput(updatedInvalidInput);
      setLoading(false);
      return;
    }

    register({
      email: email,
      password: password,
      displayName: displayName,
    })
      .then((response) => {
        setSuccessfulRequestSubmitted(true);
        setAlertMessage(response.data.message);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return !successfulRequestSubmitted ? (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            background: COLOR_BWHITE,
            borderRadius: "6px",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.04)",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: { xs: "10px", md: 4 },
              paddingBottom: { xs: "10px", md: 4 },
              paddingTop: { xs: "10px", md: 4 },
              paddingX: { xs: "10px", md: 6 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: COLOR_BNAVY }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InlineErrorMessage errorMessage={invalidInput} />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    required
                    fullWidth
                    id="email"
                    label={
                      <>
                        <BiSolidEnvelope
                          color={COLOR_BMEDIUMGRAY}
                          size={22}
                          className="me-2 mb-1"
                        />
                        Email Address
                      </>
                    }
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    required
                    fullWidth
                    name="password"
                    label={
                      <>
                        <BiSolidLock
                          color={COLOR_BMEDIUMGRAY}
                          size={22}
                          className="me-2 mb-1"
                        />
                        Password
                      </>
                    }
                    type="password"
                    id="password"
                    autoComplete="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    required
                    fullWidth
                    id="displayName"
                    label={
                      <>
                        <BiSolidUser
                          color={COLOR_BMEDIUMGRAY}
                          size={22}
                          className="me-2 mb-1"
                        />
                        Display name
                      </>
                    }
                    name="displayName"
                    autoComplete="displayName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.agreement}>
                    By clicking 'Sign up', you agree to our{" "}
                    <LaButton
                      laStyle="btn-underline-blue"
                      onClick={() => navigate("/termsandconditions")}
                    >
                      Terms and Conditions
                    </LaButton>
                    , and our{" "}
                    <LaButton
                      laStyle="btn-underline-blue"
                      onClick={() => navigate("/privacypolicy")}
                    >
                      Privacy Policy
                    </LaButton>
                    .
                  </div>
                </Grid>
              </Grid>
              <LaButton
                type="submit"
                fullWidth
                onClick={() => handleSubmit}
                laStyle="btn-navy"
                customClassName="my-3  py-12"
              >
                {loading ? (
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <LaSpinner variant="light" sm />
                  </Box>
                ) : (
                  "Sign up"
                )}
              </LaButton>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <LaLink laStyle="link-underline-blue" to="/user/login">
                    Already have an account? Log in
                  </LaLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  ) : (
    <Alert className="max-width">{alertMessage}</Alert>
  );
};

export default SignUp;
