import { BsCaretLeftFill } from "react-icons/bs";
import PageTitle from "../../../components/pageTitle/PageTitle.component";
import { COLOR_BMEDIUMGRAY, COLOR_BNAVY } from "../../../commons/LaColors";
import { useNavigate } from "react-router";
import { getUserProfile } from "../../../commons/Api";
import { useEffect, useState } from "react";
import { handleErrorRouting } from "../../../commons/LaUtils";
import Response_UserProfile from "../../../types/responses/Response.UserProfile.type";
import { Box } from "@mui/material";
import classes from "./PersonalData.module.css";
import PersonalDataDocument from "./PersonalDataDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LaSpinner from "../../../components/spinner/LaSpinner.component";

const PersonalData = () => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [data, setData] = useState<Response_UserProfile | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getUserProfile()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <LaSpinner />
  ) : (
    <div className={classes.personalDataPage}>
      <>
        <PageTitle
          title="My personal data"
          insidePage
          backButton={
            <BsCaretLeftFill
              onClick={() => navigate("/user/preferences")}
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          }
        />

        <Box sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}>
          <PDFDownloadLink
            document={<PersonalDataDocument data={data} />}
            fileName={"Lapedia_personalData_" + data?.displayName + ".pdf"}
            /* TODO: on hover, width for mobile version */
            style={{
              backgroundColor: COLOR_BNAVY,
              borderColor: COLOR_BNAVY,
              color: "white",
              alignSelf: "center",
              outline: "none",
              border: "none",
              padding: "0.5rem 1rem",
              fontSize: "0.875rem",
              fontWeight: "bold",
              borderRadius: "50px",
            }}
          >
            {({ blob, url, loading, error }) => "Download my personal data"}
          </PDFDownloadLink>
          {/*
          <LaButton
            type="submit"
            onClick={() => handleSubmit()}
            laStyle="btn-navy"
          >
            Download my personal data
          </LaButton>*/}
        </Box>
      </>
    </div>
  );
};

export default PersonalData;
