import { useEffect, useState } from "react";
import InlineAddDefinition from "../inlineAddDefinition/InlineAddDefinition";
import { useNavigate, useParams } from "react-router-dom";
import Response_Definition from "../../types/responses/Response.Definition.type";
import Box from "@mui/material/Box";
import DefinitionInTermTree from "../definitionInTermTree/DefinitionInTermTree";
import {
  getDefinition,
  getDefinition_Admin,
  getTotalDefinitionCount,
} from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import ElementCard from "../../components/elementCard/ElementCard.component";
import State_Type from "../../types/State.type";
import { handleErrorRouting, stateToString } from "../../commons/LaUtils";
import { fetchRoleFromJwt, getAuthToken } from "../../commons/AuthTokenUtils";

const DefinitionCard = (props: {
  definition?: Response_Definition;
  includeInlineAddDefinition?: boolean;
  isProfile?: boolean;
}) => {
  const { id } = useParams();
  const [definition, setDefinition] = useState<Response_Definition>();
  const [totalDefinitionCount, setTotalDefinitionCount] = useState<
    number | undefined
  >();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const token = getAuthToken();
  let role = "";
  if (token) {
    role = fetchRoleFromJwt(token);
  }

  useEffect(() => {
    setLoading(true);
    if (props.definition !== undefined) {
      setDefinition(props.definition);
      if (
        stateToString(props.definition.state) ===
        State_Type[State_Type.approved]
      ) {
        runGetTotalDefinitionCount(props.definition.termId);
      }
      setLoading(false);
    } else {
      if (id === undefined) return;
      let promise =
        role === "admin" ? getDefinition_Admin(id) : getDefinition(id);
      promise
        .then((response) => {
          setDefinition(response.data);
          if (
            stateToString(response.data.state) ===
            State_Type[State_Type.approved]
          ) {
            runGetTotalDefinitionCount(response.data.termId);
          }
        })
        .catch((error) => {
          handleErrorRouting(error, (url: string) => navigate(url));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const runGetTotalDefinitionCount = (termId: string) => {
    getTotalDefinitionCount(termId)
      .then((response) => {
        setTotalDefinitionCount(response.data);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <LaSpinner />
        </Box>
      ) : (
        definition && (
          <div className={`max-width ${props?.isProfile ? "px-0" : ""}`}>
            <ElementCard
              showExpandCollapse
              termName={definition.termName}
              termId={definition.termId}
              termTotalDefinitionCount={totalDefinitionCount}
              termState={definition.state + ""}
              definitionElement={
                <div>
                  <DefinitionInTermTree
                    definition={definition}
                    isInline={true}
                  />
                  {props.includeInlineAddDefinition && (
                    <InlineAddDefinition
                      termId={definition.termId}
                      termName={definition.termName}
                      isInline={true}
                    />
                  )}
                </div>
              }
            />
          </div>
        )
      )}
    </>
  );
};

export default DefinitionCard;
