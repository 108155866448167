import { ReactNode } from "react";
import StickyFooterItem from "./StickyFooterItem";
import classes from "./MobileFooter.module.css";
import { HiInformationCircle, HiSquares2X2 } from "react-icons/hi2";
import { MdOutlineStickyNote2 } from "react-icons/md";
import {
  COLOR_BBLUE,
  COLOR_BMEDIUMGRAY,
  COLOR_BRED,
} from "../../../commons/LaColors";
import { IoSettings } from "react-icons/io5";
import {
  fetchRoleFromJwt,
  getAuthToken,
} from "../../../commons/AuthTokenUtils";
import { MenuButton } from "../../Layout";

type FooterStickyLink = {
  title: string;
  href: string;
  identifiers: string[];
  excludes: string[];
  logo: ReactNode;
  menuButton: MenuButton;
  isActive: boolean;
};

const MobileFooter = (props: any) => {
  const token = getAuthToken();

  const footerNavLinks: FooterStickyLink[] = [
    {
      title: "Home",
      href: "/",
      identifiers: ["home"],
      excludes: [""],
      logo: (
        <HiSquares2X2
          size={27}
          color={props.showHomeMobileButton ? COLOR_BBLUE : COLOR_BMEDIUMGRAY}
        />
      ),
      menuButton: MenuButton.home,
      isActive: props.showHomeMobileButton,
    },
    {
      title: "Recently added",
      href: "/recentlyadded",
      //reloadDocument: true,
      identifiers: ["recentlyadded", "term", "definition"],
      excludes: ["settings", "user"],
      logo: (
        <MdOutlineStickyNote2
          size={27}
          color={
            props.showRecentlyAddedMobileButton
              ? COLOR_BBLUE
              : COLOR_BMEDIUMGRAY
          }
        />
      ),
      menuButton: MenuButton.recentlyAdded,
      isActive: props.showRecentlyAddedMobileButton,
    },
    {
      title: "About",
      href: "/about",
      identifiers: ["about"],
      excludes: [""],
      logo: (
        <HiInformationCircle
          size={27}
          color={props.showAboutMobileButton ? COLOR_BBLUE : COLOR_BMEDIUMGRAY}
        />
      ),
      menuButton: MenuButton.about,
      isActive: props.showAboutMobileButton,
    },
  ];

  return (
    <div className=" d-md-none w-100">
      <div
        className={`${classes.footer} d-fixed bottom-0 w-100 d-flex px-0 mx-0 rounded-t-[12px]`}
      >
        {footerNavLinks.map((el, index) => {
          return (
            <StickyFooterItem
              key={index}
              title={el?.title}
              href={el?.href}
              LOGO={el.logo}
              isActive={el.isActive}
              onClick={() => {
                props.handleMenuButtonClick(el.menuButton);
              }}
              //reloadDocument={el?.reloadDocument}
            />
          );
        })}
        {/* {logged && <StickyFooterItem key='profile' title='Profile' href='/user/preferences' LOGO={<BsPersonCircle size={27} color={COLOR_BBLUE} />} isActive={pathname.includes('user')} />} */}
        {token && fetchRoleFromJwt(token) === "admin" && (
          <StickyFooterItem
            key="adminSettings"
            title="Admin settings"
            redTitle
            href="/admin/settings"
            LOGO={<IoSettings size={27} color={COLOR_BRED} />}
            isActive={props.showAdminSettingsMobileButton}
            onClick={() => {
              props.handleMenuButtonClick(MenuButton.adminSettings);
            }}
            //reloadDocument={false}
          />
        )}
      </div>
    </div>
  );
};

export default MobileFooter;
