import LaLink from "../../components/link/LaLink.component";
import classes from "./Header.module.css";
import SearchBox from "../../search/searchBox/SearchBox";
import logoLapedia from "../../assets/logoLapedia.png";
import LoginMenu from "./loginMenu/LoginMenu";
import { useEffect, useRef, useState } from "react";
import { COLOR_BBLUE, COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { BsPersonCircle } from "react-icons/bs";
import { useNavigate } from "react-router";
import { getAuthToken } from "../../commons/AuthTokenUtils";
import { MenuButton } from "../Layout";
import { BiSearch } from "react-icons/bi";
import Notifications from "./accountMenu/Notifications";

const Header = (props: any) => {
  const [showSearchMenuButton, setShowSearchMenuButton] = useState(
    props.showSearchMenuButton,
  );

  const navigate = useNavigate();

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setShowSearchMenuButton(false);
    }
  };

  let searchElement = (
    <div
      onClick={() => {
        setShowSearchMenuButton(!showSearchMenuButton);
      }}
      className={`d-md-none d-flex flex-column justify-content-end ms-auto cursor-pointer ${
        classes.mobile_icon_default
      } ${showSearchMenuButton && classes.mobile_icon}`}
    >
      <BiSearch
        color={showSearchMenuButton ? COLOR_BBLUE : COLOR_BMEDIUMGRAY}
        className=""
        size={24}
      />
    </div>
  );

  return (
    <div className={classes.sticky}>
      <header
        className={`${classes.mainHeader} ${
          logged ? "" : classes.lockedHeader
        }`}
        ref={wrapperRef}
      >
        <div
          className={`${classes.container} ${
            showSearchMenuButton && classes.header_active
          }`}
        >
          {/* <button className={classes.btnMobileNav} onClick={showNavigation} >
          <MenuIcon className={classes.iconMobileNav} name="menu" />
          <CloseIcon className={classes.iconMobileNav} name="close" />
        </button> */}
          <div onClick={() => props.handleMenuButtonClick(MenuButton.logo)}>
            <LaLink
              //reloadDocument
              to={"/"}
              children={
                <img src={logoLapedia} className={classes.logo} alt="" />
              }
            />
          </div>
          <nav className={classes.nav}>
            <div className={classes.navdiv}>
              <ul
                className={
                  classes.horizontalNavLink + " " + classes.verticalNavOnMobile
                }
              >
                <li>
                  <LaLink
                    to={"/about"}
                    children="About"
                    laStyle="link-simple"
                  />
                </li>
                <li>
                  <LaLink
                    //reloadDocument
                    to={"/recentlyadded"}
                    children="Recently added terms"
                    laStyle="link-simple"
                  />
                </li>
              </ul>
            </div>
          </nav>
          <div className={`${classes.searchBox} col-12`}>
            <SearchBox />
          </div>
          {!logged && searchElement}
          <div
            onClick={() => {
              props.unclickAllMobileButtons();
            }}
            className=""
          >
            <LoginMenu />
          </div>

          {logged && (
            <div className={classes.accountMobile}>
              {searchElement}
              <div
                className={`d-md-none d-flex flex-column justify-content-center ${
                  classes.profilePadding
                } ${props.showNotificationsMobileButton && classes.profile}`}
                style={{ cursor: "pointer" }}
              >
                <Notifications
                  mobileVersion
                  showNotificationsMobileButton={
                    props.showNotificationsMobileButton
                  }
                  handleMenuButtonClick={props.handleMenuButtonClick}
                />
              </div>
              <div
                className={`d-md-none d-flex flex-column justify-content-end ${
                  classes.profilePadding
                } ${props.showUserAccountMobileButton && classes.profile}`}
                style={{ cursor: "pointer" }}
              >
                <BsPersonCircle
                  size={24}
                  color={
                    props.showUserAccountMobileButton
                      ? COLOR_BBLUE
                      : COLOR_BMEDIUMGRAY
                  }
                  onClick={() => {
                    props.handleMenuButtonClick(MenuButton.userAccount);
                    navigate("/user/preferences");
                  }}
                  className="my-auto"
                />
              </div>
            </div>
          )}
        </div>
        {showSearchMenuButton && (
          <div
            style={{ maxWidth: "100vw", padding: "10px 12px" }}
            className="mx-auto"
          >
            <SearchBox onCloseHandler={() => setShowSearchMenuButton(false)} />
          </div>
        )}
      </header>
    </div>
  );
};
export default Header;
