import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classes from "./Layout.module.css";
import { useState } from "react";

export enum MenuButton {
  logo,
  notifications,
  userAccount,
  home,
  recentlyAdded,
  about,
  adminSettings,
}

const Layout = () => {
  const [showNotificationsMobileButton, setShowNotificationsMobileButton] =
    useState(false);
  const [showUserAccountMobileButton, setShowUserAccountMobileButton] =
    useState(false);
  //
  const [showHomeMobileButton, setShowHomeMobileButton] = useState(true);
  const [showRecentlyAddedMobileButton, setShowRecentlyAddedMobileButton] =
    useState(false);
  const [showAboutMobileButton, setShowAboutMobileButton] = useState(false);
  const [showAdminSettingsMobileButton, setShowAdminSettingsMobileButton] =
    useState(false);

  const unclickAllMobileButtons = () => {
    setShowNotificationsMobileButton(false);
    setShowUserAccountMobileButton(false);
    //
    setShowHomeMobileButton(false);
    setShowRecentlyAddedMobileButton(false);
    setShowAboutMobileButton(false);
    setShowAdminSettingsMobileButton(false);
  };

  const handleMenuButtonClick = (button: MenuButton) => {
    unclickAllMobileButtons();
    switch (button) {
      case MenuButton.logo:
      case MenuButton.home:
        setShowHomeMobileButton(true);
        break;
      case MenuButton.notifications:
        setShowNotificationsMobileButton(true);
        break;
      case MenuButton.userAccount:
        setShowUserAccountMobileButton(true);
        break;
      case MenuButton.recentlyAdded:
        setShowRecentlyAddedMobileButton(true);
        break;
      case MenuButton.about:
        setShowAboutMobileButton(true);
        break;
      case MenuButton.adminSettings:
        setShowAdminSettingsMobileButton(true);
        break;
    }
  };

  return (
    <div className={classes.layout}>
      <Header
        unclickAllMobileButtons={unclickAllMobileButtons}
        handleMenuButtonClick={handleMenuButtonClick}
        showNotificationsMobileButton={showNotificationsMobileButton}
        showUserAccountMobileButton={showUserAccountMobileButton}
      />
      <div className={classes.mainContainer}>
        <Outlet />
      </div>
      <Footer
        unclickAllMobileButtons={unclickAllMobileButtons}
        handleMenuButtonClick={handleMenuButtonClick}
        showHomeMobileButton={showHomeMobileButton}
        showRecentlyAddedMobileButton={showRecentlyAddedMobileButton}
        showAboutMobileButton={showAboutMobileButton}
        showAdminSettingsMobileButton={showAdminSettingsMobileButton}
      />
    </div>
  );
};
export default Layout;
