import { Link } from "react-router-dom";
import "./LaLink.style.css";

type LaLinkProps = {
  to: string;
  children: any;
  key?: string;
  laStyle?: string;
  onClick?: any;
  isActive?: boolean;
  //reloadDocument?: boolean;
};

const STYLES = [
  "link-simple",
  "link-simple-right",
  "link-profile",
  "link-title",
  "link-big-title",
  "link-border",
  "link-solid",
  "link-solid-yellow",
  "link-underline-blue",
];

const LaLink = (props: LaLinkProps) => {
  let laStyle =
    props.laStyle === undefined || !STYLES.includes(props.laStyle)
      ? undefined
      : props.laStyle;

  switch (laStyle) {
    case "link-simple-right":
    case "link-border":
    case "link-solid-yellow":
    case "link-solid":
      laStyle = "link-simple " + laStyle;
      break;
  }

  return (
    <Link
      //reloadDocument={props.reloadDocument}
      className={`${laStyle} ${props.isActive && " active "}`}
      to={props.to}
      key={props.key}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  );
};

export default LaLink;
