import { useState } from "react";
import classes from "./PreviousNext.module.css";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { COLOR_BBLUE } from "../../commons/LaColors";

const PreviousNext = (props: {
  nextPage: string | undefined;
  orderedBy?: string;
  getData: any;
}) => {
  const FIRST_PAGE = "-1";

  const [previousPages, setPreviousPages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<string>(FIRST_PAGE);

  const nextPreviousPageHandler = (next: boolean): string | undefined => {
    let page;
    if (next) {
      if (props.nextPage !== undefined) {
        previousPages.push(currentPage);
        setPreviousPages(previousPages);
        setCurrentPage(props.nextPage);
      }
      page = props.nextPage;
    } else {
      if (previousPages.length === 0) {
        console.error("no previous page"); // TODO-adi: throw
      }
      let previousPage = previousPages.pop();
      setPreviousPages(previousPages);
      if (previousPage !== undefined) {
        setCurrentPage(previousPage);
      }
      page = previousPage === FIRST_PAGE ? undefined : previousPage;
    }

    return page;
  };

  return (
    <div className={`${classes.previousNext} `}>
      {previousPages.length !== 0 && (
        <div className={classes.previous}>
          <GoChevronLeft
            color={COLOR_BBLUE}
            className="cursor-pointer"
            size={44}
            onClick={() => {
              let page = nextPreviousPageHandler(false);
              props.getData(props.orderedBy, page);
            }}
          />
        </div>
      )}
      {props.nextPage !== undefined ? (
        <div className={classes.next}>
          <GoChevronRight
            color={COLOR_BBLUE}
            size={44}
            className="cursor-pointer"
            onClick={() => {
              let page = nextPreviousPageHandler(true);
              props.getData(props.orderedBy, page);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
export default PreviousNext;
