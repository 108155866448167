import Card from "react-bootstrap/Card";
import { useState } from "react";
import classes from "./ElementCard.module.css";
import LaLink from "../link/LaLink.component";
import ExpandCollapse from "../expandCollapse/ExpandCollapse.component";
import TotalElementCount from "../totalElementCount/TotalElementCount.component";
import { epochToDateTime, toPrettyTermName } from "../../commons/LaUtils";
import { fetchRoleFromJwt } from "../../commons/AuthTokenUtils";
import { Alert } from "react-bootstrap";
import ApproveRejectTermElement from "../../terms/approveRejectTermElement/ApproveRejectTermElement";
import Element_Type from "../../types/Element.type";
import { getAuthToken } from "../../commons/AuthTokenUtils";

type ElementCardProps = {
  termId: string;
  termName: string;
  termCreatedOn?: number;
  termLastUpdatedOn?: number;
  termTotalDefinitionCount?: number;
  termState: string;
  definitionElement?: any;
  showExpandCollapse?: boolean;
};

const ElementCard = (props: ElementCardProps) => {
  const [approvedSuccessfully, setApprovedSuccessfully] =
    useState<boolean>(false);
  const [rejectedSuccessfully, setRejectedSuccessfully] =
    useState<boolean>(false);

  let dates =
    props.termCreatedOn === undefined
      ? undefined
      : "Added: " + epochToDateTime(props.termCreatedOn);

  let token = getAuthToken();
  let role = "";
  if (token) {
    role = fetchRoleFromJwt(token);
  }
  let to = "/term/" + props.termId + "/" + toPrettyTermName(props.termName);

  return (
    <>
      {rejectedSuccessfully ? (
        <Alert>The term '{props.termId}' has been rejected successfully.</Alert>
      ) : approvedSuccessfully ? (
        <Alert>The term '{props.termId}' has been approved successfully.</Alert>
      ) : (
        <Card className={`${classes.element}`}>
          <Card.Body>
            <Card.Title className="mb-1">
              <div className={classes.elementTitle}>
                {/* TODO-adi: compare using state not string*/}
                {props.termState === "approved" ? (
                  //<LaLink reloadDocument to={to} laStyle="link-title">
                  <LaLink to={to} laStyle="link-title">
                    {props.termName}
                    {props.showExpandCollapse && <ExpandCollapse />}
                  </LaLink>
                ) : (
                  <div className={`${classes.pendingTermTitle}`}>
                    {props.termName} (Pending approval)
                  </div>
                )}
              </div>
            </Card.Title>
            <>
              <div>
                {props.termTotalDefinitionCount && (
                  <TotalElementCount
                    countedElement={Element_Type.definition}
                    totalElementCount={props.termTotalDefinitionCount}
                    isInside={true}
                  />
                )}
              </div>
              <div className={classes.elementDate}>{dates}</div>
              {props.definitionElement !== undefined && (
                <div className={classes.insideDefinition}>
                  {props.definitionElement}
                </div>
              )}
              <div>
                {props.termState === "pending" && role === "admin" && (
                  <ApproveRejectTermElement
                    termId={props.termId}
                    termName={props.termName}
                    setApprovedSuccessfully={setApprovedSuccessfully}
                    setRejectedSuccessfully={setRejectedSuccessfully}
                  />
                )}
              </div>
            </>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ElementCard;
