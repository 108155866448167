import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  getAdminNotifications_Admin,
  getAllUserNotifications_Admin,
  markUserNotificationsAsSeen,
} from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { AxiosResponse } from "axios";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import Response_Notification from "../../types/responses/Response.Notification.type";
import NotificationCard from "../notificationCard/NotificationCard";
import { getUserUnreadNotifications } from "../../commons/Api";
import { BsCaretLeftFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { handleErrorRouting } from "../../commons/LaUtils";
import LaButton from "../../components/button/LaButton.component";
import { RiCheckDoubleFill } from "react-icons/ri";

export enum NotificationCardList_Type {
  adminNotifications,
  authorNotifications,
  authorSelfNotifications,
}

const NotificationCardList = (props: {
  type: NotificationCardList_Type;
  id?: string;
}) => {
  const [data, setData] = useState<Response_Notification[]>();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let title = "No title";
  let showAll = false;

  if (props.type === NotificationCardList_Type.adminNotifications) {
    title = "Admin notifications";
    showAll = true;
  }
  if (props.type === NotificationCardList_Type.authorNotifications) {
    title = "Author notifications";
    showAll = true;
  }
  if (props.type === NotificationCardList_Type.authorSelfNotifications) {
    title = "Notifications";
  }

  useEffect(() => {
    getData(undefined);
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const getData = (page: string | undefined) => {
    setData(undefined);

    let promise: Promise<AxiosResponse<Response_Notification[], any>>;
    if (props.type === NotificationCardList_Type.adminNotifications) {
      promise = getAdminNotifications_Admin(true);
    } else if (props.type === NotificationCardList_Type.authorNotifications) {
      if (!props.id) {
        return Promise.reject("id undefined");
      }
      promise = getAllUserNotifications_Admin(props.id);
    } else {
      // props.type === NotificationCardList_Type.authorSelfNotifications
      promise = getUserUnreadNotifications();
    }

    promise
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearNotifications = () => {
    var list: string[] = [];
    data?.map((n) => list.push(n.id));
    markUserNotificationsAsSeen({
      ids: list,
    })
      .then((response) => {
        setData([]);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        window.location.reload();
      });
  };

  return loading ? (
    <Box>
      <LaSpinner />
    </Box>
  ) : (
    <div className="max-width row-sm">
      <div
        style={{
          paddingTop: `${
            props.type === NotificationCardList_Type.adminNotifications
              ? "20px"
              : " "
          }`,
        }}
      >
        <PageTitle
          title={title}
          insidePage={
            props.type === NotificationCardList_Type.adminNotifications
          }
          backButton={
            props.type === NotificationCardList_Type.adminNotifications ? (
              <BsCaretLeftFill
                onClick={() => navigate("/admin/settings")}
                className="ms-auto  d-md-none"
                size={24}
                color={COLOR_BMEDIUMGRAY}
              />
            ) : null
          }
        />
      </div>
      <div className="d-flex justify-content-end">
        <LaButton
          laStyle="btn-underline"
          onClick={() => clearNotifications()}
          children={
            <div>
              <span>Clear notifications</span>{" "}
              <RiCheckDoubleFill size={16} fontWeight={700} />
            </div>
          }
        />
      </div>
      {data !== undefined &&
        data.map((notification: Response_Notification, index) => {
          return (
            <NotificationCard
              showAll={showAll}
              notification={notification}
              key={index}
            />
          );
        })}

      {/*<PreviousNext nextPage={nextPage} getData={getData} />*/}
    </div>
  );
};

export default NotificationCardList;
