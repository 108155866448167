import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { fetchRoleFromJwt } from "../../../commons/AuthTokenUtils";
import { getAuthToken } from "../../../commons/AuthTokenUtils";
import { BsPersonCircle } from "react-icons/bs";
import {
  COLOR_BBLUE,
  COLOR_BDARKGRAY,
  COLOR_BRED,
} from "../../../commons/LaColors";
import classes from "./AccountMenu.module.css";
import AccountCircle from "@mui/icons-material/AccountCircle";

const AccountMenu = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const token = getAuthToken();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    props.logout();
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const preferencesHandler = () => {
    navigate(`/user/preferences/profile/`);
  };
  const adminSettingsHandler = () => {
    navigate(`/admin/settings/termcard`);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/*<Avatar sx={{ width: 32, height: 32 }}>A</Avatar>*/}
            <BsPersonCircle size={24} color={COLOR_BBLUE} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            color: COLOR_BDARKGRAY,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className={classes.menuItem} onClick={preferencesHandler}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          My account
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={logoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        {token && fetchRoleFromJwt(token) === "admin" && <Divider />}
        {token && fetchRoleFromJwt(token) === "admin" && (
          <MenuItem
            className={classes.menuItemAdmin}
            onClick={adminSettingsHandler}
          >
            <ListItemIcon>
              <Settings fontSize="small" sx={{ color: COLOR_BRED }} />
            </ListItemIcon>
            Admin settings
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
