import Element_Type from "../../types/Element.type";
import classes from "./TotalElementCount.module.css";

const TotalElementCount = (props: {
  countedElement: Element_Type;
  totalElementCount: number;
  isInside?: boolean;
}) => {
  const count = props.totalElementCount ? props.totalElementCount : 0;
  let elementTypeAsString =
    props.countedElement === Element_Type.term ? "Term" : "Definition";
  if (props.totalElementCount !== 1) {
    elementTypeAsString = elementTypeAsString + "s";
  }
  return (
    <>
      {props.totalElementCount !== undefined && (
        <span
          className={`${classes.totalElementCount} ${
            props.isInside ? ` ${classes.inside}` : " "
          }`}
        >
          {count + " " + elementTypeAsString}
        </span>
      )}
    </>
  );
};

export default TotalElementCount;
