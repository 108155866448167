export const COLOR_BNAVY = "#286385";
export const COLOR_BNAVY_LIGHT = "#d4e0e7";
export const COLOR_BNAVY_HOVER = "#53829d";
/* */
export const COLOR_BBLUE = "#3788B8";
export const COLOR_BBLUE_LIGHT = "#d7e7f1";
export const COLOR_BBLUE_HOVER = "#5fa0c6";
/* */
export const COLOR_BYELLOW = "#FFC933";
export const COLOR_BYELLOW_HOVER = "#ffd45c";
/* */
export const COLOR_BRED = "#DB4A21";
export const COLOR_BRED_HOVER = "#e26e4d";
/* */
export const COLOR_BDARKGRAY = "#353535";
export const COLOR_BMEDIUMGRAY = "#969696";
export const COLOR_BLIGHTGRAY = "#E6E6E6";
export const COLOR_BEXTRALIGHTGRAY = "#FAFAFA";
/* */

export const COLOR_BWHITE = "#FFFFFF";
