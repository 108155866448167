import { useEffect, useState } from "react";
import ElementCard from "../../components/elementCard/ElementCard.component";
import Box from "@mui/material/Box";
import PreviousNext from "../../components/previousNext/PreviousNext.component";
import { searchDefinitionsFull, searchTerms } from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import { useNavigate, useParams } from "react-router";
import Response_Term from "./../../types/responses/Response.Term.type";
import DefinitionCard from "../../definitions/definitionCard/DefinitionCard";
import classes from "./SearchResultPage.module.css";
import { handleErrorRouting } from "../../commons/LaUtils";
import Response_Definition from "../../types/responses/Response.Definition.type";

/**
 * Todo-adi: refactor code
 */
const SearchResultsPage = () => {
  const { query } = useParams();
  const [terms, setTerms] = useState<Response_Term[]>();
  const [definitions, setDefinitions] = useState<Response_Definition[]>();
  const [loadingTerms, setLoadingTerms] = useState(true);
  const [loadingDefinitions, setLoadingDefinitions] = useState(true);
  const [nextPageTerms, setNextPageTerms] = useState<string | undefined>(
    undefined,
  );
  const [nextPageDefinitions, setNextPageDefinitions] = useState<
    string | undefined
  >(undefined);
  const navigate = useNavigate();

  let title = "Search result for query '" + query + "'";
  let size = 5;

  useEffect(() => {
    getTerms(undefined, undefined);
    getDefinitions(undefined, undefined);
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const getTerms = (
    orderedBy: string | undefined,
    page: string | undefined,
  ) => {
    setTerms(undefined);
    if (!query) {
      return;
    }
    let adoptedPage: number = page === undefined ? 0 : +page;
    searchTerms(query, adoptedPage, size)
      .then((response) => {
        setTerms(response.data.terms);
        setNextPageTerms(
          response.data.nextPage === null ? undefined : response.data.nextPage,
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoadingTerms(false);
      });
  };

  const getDefinitions = (
    orderedBy: string | undefined,
    page: string | undefined,
  ) => {
    setDefinitions(undefined);
    if (!query) {
      return;
    }
    let adoptedPage: number = page === undefined ? 0 : +page;
    searchDefinitionsFull(query, adoptedPage, size)
      .then((response) => {
        setDefinitions(response.data.definitions);
        setNextPageDefinitions(
          response.data.nextPage === null ? undefined : response.data.nextPage,
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoadingDefinitions(false);
      });
  };

  return (
    <div className="max-width row-sm">
      <PageTitle title={title} />
      {loadingTerms ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : (
        <>
          <div className={classes.smallTitle}>Terms</div>
          {terms !== undefined &&
            terms.map((term: Response_Term) => {
              return (
                <ElementCard
                  key={term.id}
                  termId={term.id}
                  termName={term.name}
                  termCreatedOn={term.createdOn}
                  termLastUpdatedOn={term.lastUpdatedOn}
                  termTotalDefinitionCount={term.totalDefinitionCount}
                  termState={term.state + ""}
                />
              );
            })}

          {!!nextPageTerms && (
            <PreviousNext nextPage={nextPageTerms} getData={getTerms} />
          )}

          {/*////////////*/}
          {/* <div className={classes.pageHr}>
            <hr />
          </div> */}

          {/*////////////*/}

          {loadingDefinitions ? (
            <Box>
              <LaSpinner />
            </Box>
          ) : (
            <>
              <div className={classes.topMargin2}></div>
              <div className={`${classes.smallTitle}`}>Definitions</div>
              {definitions !== undefined &&
                definitions.map(
                  (definitionAsSearchResult: Response_Definition, index) => {
                    return (
                      <DefinitionCard
                        definition={definitionAsSearchResult}
                        key={index}
                        isProfile
                      />
                    );
                  },
                )}
              <PreviousNext
                nextPage={nextPageDefinitions}
                getData={getDefinitions}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResultsPage;
