import { Outlet, useLocation, useNavigate } from "react-router";
import classes from "./VerticalMenu.module.css";
import VerticalMenuSectionItems from "../../types/VerticalMenuSectionItems.type";
import VerticalMenuSection from "../../layout/verticalMenu/verticalMenuSection/VerticalMenuSection";
import LaButton from "../../components/button/LaButton.component";
import { logout } from "../../commons/AuthTokenUtils";

const VerticalMenu = (props: {
  sections: VerticalMenuSectionItems[];
  title: string;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const logoutHandler = () => {
    logout(() => navigate("/"));
  };
  return (
    <div className={classes.menuWrapper}>
      <div
        className={`${
          classes.menuItems
        } d-flex flex-column justify-content-between justify-content-md-start ${
          pathname === "/user/preferences" || pathname === "/admin/settings"
            ? ""
            : ` ${classes.noDisplay}`
        }`}
      >
        <h1 className={classes.menuTitle}>{props.title}</h1>
        {props.sections.map((section, index) => {
          return <VerticalMenuSection key={index} section={section} />;
        })}
        <div
          className={`mt-auto px-3 ${
            pathname === "/user/preferences" ? " d-md-none " : `d-none`
          }`}
        >
          <LaButton
            type="button"
            fullWidth
            onClick={() => logoutHandler()}
            laStyle="btn-navy"
            customClassName="my-4"
          >
            Log Out
          </LaButton>
        </div>
      </div>
      <div
        className={`${classes.laOutletContainer} ${
          pathname === "/user/preferences" || pathname === "/admin/settings"
            ? ` ${classes.noDisplay}`
            : " "
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default VerticalMenu;
