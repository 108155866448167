import { Navigate } from "react-router-dom";
import { fetchRoleFromJwt, getAuthToken } from "../commons/AuthTokenUtils";

export const AdminProtectedRoute = (props: { children: any }) => {
  let authToken = getAuthToken();
  if (!authToken) {
    return <Navigate to="/user/login" />;
  }

  let role = fetchRoleFromJwt(authToken);
  if (role !== "admin") {
    return <Navigate to="/" />;
  }

  return props.children;
};
