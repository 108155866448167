import nunito from "../../../assets/fonts/Nunito-VariableFont_wght.ttf";
import logoLapedia from "../../../assets/logoLapedia.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Response_UserProfile from "../../../types/responses/Response.UserProfile.type";
import { epochToDateTime } from "../../../commons/LaUtils";

Font.register({
  family: "Nunito",
  format: "truetype",
  src: nunito,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  section: {
    margin: 30,
    padding: 10,
    flexGrow: 1,
  },
  img: {
    width: 120,
  },
  text: {
    fontSize: 14,
    fontFamily: "Nunito",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Nunito",
  },
  textFooter: {
    fontSize: 9,
    fontFamily: "Nunito",
  },
});

const PersonalDataDocument = (props: {
  data: Response_UserProfile | undefined;
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text> </Text>
          <Image src={logoLapedia} style={styles.img} />
          <Text> </Text>
          <Text> </Text>
          <Text style={styles.textTitle}>
            Personal data of the user with id '{props.data?.id}'
          </Text>
          <Text> </Text>
          <Text style={styles.text}>Email: {props.data?.email}</Text>
          <Text style={styles.text}>
            Display name: {props.data?.displayName}
          </Text>
          <Text style={styles.text}>First name: {props.data?.firstName}</Text>
          <Text style={styles.text}>Last name: {props.data?.lastName}</Text>

          <Text> </Text>
          <Text style={styles.textFooter}>
            This information was generated upon request by the user with id '
            {props.data?.id}' on {epochToDateTime(Date.now())} (UTC
            {new Date().getTimezoneOffset() / 60}).
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PersonalDataDocument;
