import "./LaButton.style.css";

type LaButtonProps = {
  onClick?: () => void;
  onMouseDown?: (e: any) => void;
  children: any;
  laStyle?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  customClassName?: string;
};

const STYLES = [
  "btn-blue",
  "btn-navy",
  "btn-yellow",
  "btn-gray",
  "btn-green",
  "btn-red",
  "btn-voting",
  "btn-voting-clicked",
  "btn-underline",
  "btn-underline-blue",
  "btn-text-gray",
  "btn-sign",
  "btn-icon",
];

const LaButton = (props: LaButtonProps) => {
  let disabled = props.disabled === undefined ? false : props.disabled;

  let fullWidth =
    props.fullWidth === undefined || !props.fullWidth ? "" : "fullWidth";

  let className =
    props.laStyle === undefined || !STYLES.includes(props.laStyle)
      ? STYLES[0]
      : props.laStyle;

  switch (className) {
    case "btn-blue":
    case "btn-navy":
    case "btn-yellow":
    case "btn-gray":
    case "btn-green":
    case "btn-red":
    case "btn-sign":
      className = className + " btn ";
      break;
    case "btn-underline-blue":
      className = className + " btn-underline ";
      break;
    case "btn-voting":
      className = className + " btn-voting ";
      break;
    case "btn-voting-clicked":
      className = className + " btn-voting-clicked ";
  }
  className = className + " shadow-none " + fullWidth;

  return (
    <button
      type={props.type}
      className={`${className} ${props.customClassName}`}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      disabled={disabled}
    >
      {props.children}
    </button>
  );
};

export default LaButton;
