import jwt_decode from "jwt-decode";
import LaJwtPayload from "../types/LaJwtPayload.type";

const ATTR_AUTH_TOKEN_STORAGE = "lapedia_auth_token";

export const getAuthToken = () => {
  return localStorage.getItem(ATTR_AUTH_TOKEN_STORAGE);
};
export const removeAuthToken = () => {
  localStorage.removeItem(ATTR_AUTH_TOKEN_STORAGE);
};

export const fetchUserIdFromJwt = (token: string): string => {
  return jwt_decode<LaJwtPayload>(token).userId;
};

export const fetchRoleFromJwt = (token: string): string => {
  return jwt_decode<LaJwtPayload>(token).role;
};

export const fetchEmailFromJwt = (token: string): string => {
  return jwt_decode<LaJwtPayload>(token).sub;
};

export const login = (jwt: string, navigate: () => void) => {
  localStorage.setItem(ATTR_AUTH_TOKEN_STORAGE, jwt);
  if (navigate) {
    navigate();
    window.location.reload();
  }
};

export const logout = (navigate?: () => void) => {
  removeAuthToken();
  if (navigate) {
    navigate();
  }
  window.location.reload();
};
