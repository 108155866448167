import { Navigate } from "react-router-dom";
import { getAuthToken } from "../commons/AuthTokenUtils";

export const AuthorProtectedRoute = (props: { children: any }) => {
  let authToken = getAuthToken();
  if (!authToken) {
    return <Navigate to="/user/login" />;
  }
  return props.children;
};
