import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Response_UserProfilePublic from "../../types/responses/Response.UserProfilePublic.type";
import { EMPTY_USER_PROFILE_PUBLIC } from "../../commons/LaConstants";
import { getUserProfilePublic } from "../../commons/Api";
import { epochToOnlyDate, handleErrorRouting } from "../../commons/LaUtils";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import TotalElementCount from "../../components/totalElementCount/TotalElementCount.component";
import Element_Type from "../../types/Element.type";
import classes from "./UserProfilePage.module.css";
import DefinitionCardList, {
  DefinitionCardList_Type,
} from "../../definitions/definitionCardList/DefinitionCardList";
import LaButton from "../../components/button/LaButton.component";
import { fetchUserIdFromJwt, getAuthToken } from "../../commons/AuthTokenUtils";

const UserProfilePage = (props: {
  propTermId?: string;
  blockUrlCorrection?: boolean;
}) => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [userProfilePublic, setUserProfilePublic] =
    useState<Response_UserProfilePublic>(EMPTY_USER_PROFILE_PUBLIC);
  const navigate = useNavigate();

  const [about, setAbout] = useState<string | undefined>(undefined);

  let myId = "";
  const token = getAuthToken();
  if (token) {
    myId = fetchUserIdFromJwt(token);
  }

  useEffect(() => {
    getData(userId);
  }, []);

  const getData = (userId: string | undefined) => {
    if (!userId) return;
    setLoading(true);
    getUserProfilePublic(userId)
      .then((response) => {
        setUserProfilePublic(response.data);
        setAbout(response.data.about);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <LaSpinner />
        </Box>
      ) : (
        <div className={`max-width row-sm`}>
          <div className={"d-flex justify-content-between"}>
            <PageTitle
              avatar
              title={
                userProfilePublic?.displayName !== undefined
                  ? userProfilePublic?.displayName
                  : "<>"
              }
              subTitle={
                <>
                  <TotalElementCount
                    isInside
                    countedElement={Element_Type.definition}
                    totalElementCount={userProfilePublic.totalDefinitionCount}
                  />
                  <div className={classes.grayed}>
                    {"Member since " +
                      epochToOnlyDate(userProfilePublic.createdOn)}
                  </div>
                </>
              }
            />
            {myId === userId && (
              <div className="align-self-start">
                <LaButton
                  onClick={() => {
                    navigate("/user/preferences/profile/");
                  }}
                  children={"Edit profile"}
                  laStyle="btn-yellow"
                />
              </div>
            )}
          </div>
          <div
            className={`${classes.smallTitle} ${classes.marginBottomUserPage}`}
          >
            About
          </div>
          {about ? (
            <div>{about}</div>
          ) : (
            <div className={classes.emptyAbout}>Empty</div>
          )}
          <DefinitionCardList
            id={userId}
            type={DefinitionCardList_Type.authorDefinitionsPublic}
            ignoreBackButton
          />
        </div>
      )}
    </>
  );
};

export default UserProfilePage;
