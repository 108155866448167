import { TbArrowBigDown } from "react-icons/tb";
import { COLOR_BBLUE, COLOR_BWHITE } from "../../../commons/LaColors";

const DownvoteNotClicked = () => {
  return (
    <TbArrowBigDown
      size={34}
      color={COLOR_BBLUE}
      style={{
        padding: "7px",
        background: COLOR_BWHITE,
        borderRadius: "50%",
        border: `1.2px solid ${COLOR_BBLUE}`,
      }}
    />
  );
};

export default DownvoteNotClicked;
