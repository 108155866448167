import { Dispatch, SetStateAction, useState } from "react";
import { Alert } from "react-bootstrap";
import LaButton from "../../components/button/LaButton.component";
import Form from "react-bootstrap/Form";
import { createDefinition } from "../../commons/Api";
import classes from "./NewTermForm.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import { useNavigate } from "react-router";
import {
  handleErrorRouting,
  preprocessReactQuillContent,
  verifyDefinitionContentAndAlert,
  verifyTermNameAndAlert,
} from "../../commons/LaUtils";
import InlineErrorMessage from "../../components/alerts/InlineErrorMessage.component";

type NewTermFormProps = {
  setIsSent?: Dispatch<SetStateAction<boolean>>;
};

const NewTermForm = ({ setIsSent }: NewTermFormProps) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<string>("");
  const [suggestedTermName, setSuggestedTermName] = useState<string>("");
  const [sent, setSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const navigate = useNavigate();

  const suggestTermHandler = () => {
    setErrorMessage([]);

    if (!verifyTermNameAndAlert(suggestedTermName)) {
      return;
    }

    if (!verifyDefinitionContentAndAlert(content)) {
      return;
    }

    let preprocessedContent = preprocessReactQuillContent(content);

    setLoading(true);
    createDefinition({
      termName: suggestedTermName,
      content: preprocessedContent,
    })
      .then((response) => {
        setSent(true);
        setIsSent && setIsSent(true);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {!sent ? (
        <div className={classes.newterm}>
          <div className="py-4 my-1">
            <Form.Group>
              <InlineErrorMessage errorMessage={errorMessage} />
              {/* <Form.Label>Term</Form.Label> */}
              <Form.Control
                type="text"
                className={classes.newtermTop}
                placeholder="Add term name.."
                value={suggestedTermName}
                onChange={(e) => {
                  setSuggestedTermName(e.target.value);
                }}
              />
              {/* <Form.Label>Definition</Form.Label> */}
              <Form.Text></Form.Text>
              <ReactQuill
                value={content}
                style={{ height: "300px" }}
                placeholder="Add definition.."
                onChange={(value) => {
                  setContent(value);
                }}
                className={`quill-inline ${classes.quillContainer}`}
              />
            </Form.Group>
          </div>
          <div>
            <LaButton
              onClick={() => suggestTermHandler()}
              fullWidth={true}
              laStyle={"btn-yellow"}
              customClassName="mb-3 mt-2"
              children={
                loading ? (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <LaSpinner variant="light" sm />
                  </Box>
                ) : (
                  "Submit"
                )
              }
            />
          </div>
        </div>
      ) : (
        <Alert>Your new term has been submitted successfully.</Alert>
      )}
    </>
  );
};

export default NewTermForm;
