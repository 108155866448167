import "./ExpandCollapse.style.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const ExpandCollapse = () => {
  return (
    <span className="link-expand-collapse">
      <KeyboardDoubleArrowRightIcon fontSize="inherit" />
    </span>
  );
};

export default ExpandCollapse;
