/**
 * Copied from https://github.com/mui/material-ui/blob/v5.12.2/docs/data/material/getting-started/templates/sign-up/SignUp.tsx
 */
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  COLOR_BDARKGRAY,
  COLOR_BMEDIUMGRAY,
  COLOR_BNAVY,
  COLOR_BWHITE,
} from "../../commons/LaColors";
import StyledTextField from "../../components/mui/StyledTextField";
import LaButton from "../../components/button/LaButton.component";
import { resetPassword, verifyResetPasswordToken } from "../../commons/Api";
import { useNavigate, useParams } from "react-router";
import { logout } from "../../commons/AuthTokenUtils";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import InlineErrorMessage from "../../components/alerts/InlineErrorMessage.component";
import { BiSolidLock } from "react-icons/bi";

const theme = createTheme();

const ResetPassword = () => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string[]>([]);
  const { token } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token === undefined || token === null) {
      navigate("/notice/Invalid token!");
    }
    setLoading(true);
    verifyResetPasswordToken(token)
      .then((response) => {
        if (response.data === false) {
          logout(() => navigate("/notice/Invalid token!"));
        }
      })
      .catch((error) => {
        logout(() =>
          navigate(
            "/notice/Failed to reset password! Please retry your request.",
          ),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage([]);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let newPassword = data.get("newPassword");
    let repeatNewPassword = data.get("repeatNewPassword");

    let updatedErrorMessage: string[] = [];

    if (
      !newPassword ||
      typeof newPassword !== "string" ||
      !repeatNewPassword ||
      typeof repeatNewPassword !== "string"
    ) {
      updatedErrorMessage.push("Missing a required field");
    }

    if (newPassword !== repeatNewPassword) {
      updatedErrorMessage.push("Passwords do not match");
    }

    if (updatedErrorMessage.length !== 0) {
      setErrorMessage(errorMessage);
      return;
    }

    // TODO-adi: token validity
    if (token === undefined || token === null) {
      return Promise.reject("Invalid token");
    }

    setLoading(true);
    resetPassword({
      token: token,
      newPassword: newPassword,
    })
      .then((response) => {
        logout(() => navigate("/notice/" + response.data));
      })
      .catch((error) => {
        logout(() =>
          navigate(
            "/notice/Failed to reset password. Please retry your request.",
          ),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return loading ? (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <LaSpinner variant="light" sm />
    </Box>
  ) : (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          background: COLOR_BWHITE,
          borderRadius: "6px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: { xs: "10px", md: 4 },
            paddingBottom: { xs: "10px", md: 4 },
            paddingTop: { xs: "10px", md: 4 },
            paddingX: { xs: "10px", md: 6 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: COLOR_BNAVY }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color={COLOR_BDARKGRAY}>
            Please enter your new password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <InlineErrorMessage errorMessage={errorMessage} />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="newPassword"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  New password
                </>
              }
              name="newPassword"
              autoComplete="newPassword"
              type="password"
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="repeatNewPassword"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />{" "}
                  Repeat new password
                </>
              }
              name="repeatNewPassword"
              autoComplete="repeatNewPassword"
              type="password"
            />
            <LaButton
              type="submit"
              fullWidth
              onClick={() => handleSubmit}
              laStyle="btn-navy"
              customClassName="my-3  py-12"
            >
              Reset password
            </LaButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
