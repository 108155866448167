import { Modal } from "react-bootstrap";
import classes from "./LaModal.module.css";

type LaModalProps = {
  onHide: () => void;
  show: boolean;
  title?: string;
  body: any;
  footer?: any;
  backdrop?: any;
};

const LaModal = (props: LaModalProps) => {
  let backdrop = props.backdrop ? props.backdrop : true;
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      size="xl"
      contentClassName="mt-auto mt-md-0"
      dialogClassName="m-0 h-100 m-md-auto justify-content-end"
      backdrop={backdrop}
    >
      {/*
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>{props.title}</Modal.Title>
      </Modal.Header>
  */}
      <Modal.Body className={`${classes.modalBody}`}>{props.body}</Modal.Body>
      {props.footer !== undefined && (
        <Modal.Footer>{props.footer}</Modal.Footer>
      )}
    </Modal>
  );
};

export default LaModal;
