import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import classes from "./ChangePassword.module.css";
import PageTitle from "../../../components/pageTitle/PageTitle.component";
import { changePassword, getUserProfile } from "../../../commons/Api";
import LaButton from "../../../components/button/LaButton.component";
import { Alert } from "react-bootstrap";
import StyledTextField from "../../../components/mui/StyledTextField";
import { useNavigate } from "react-router";
import { logout } from "../../../commons/AuthTokenUtils";
import {
  analyzeAxiosError,
  handleErrorRouting,
  isValidPassword,
} from "../../../commons/LaUtils";
import LaSpinner from "../../../components/spinner/LaSpinner.component";
import InlineErrorMessage from "../../../components/alerts/InlineErrorMessage.component";
import { Typography } from "@mui/material";
import { BiSolidLock } from "react-icons/bi";
import { COLOR_BMEDIUMGRAY } from "../../../commons/LaColors";
import { BsCaretLeftFill } from "react-icons/bs";

const ChangePassword = () => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [email, setEmail] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage([]);
    setLoading(true);
    getUserProfile()
      .then((response) => {
        setEmail(response.data.email);
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage([]);

    let updatedErrorMessage: string[] = [];

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let oldPassword = data.get("oldPassword");
    let newPassword = data.get("newPassword");
    let repeatNewPassword = data.get("repeatNewPassword");

    if (!oldPassword || typeof oldPassword !== "string") {
      updatedErrorMessage.push("Invalid old password");
    }
    if (
      !newPassword ||
      typeof newPassword !== "string" ||
      !isValidPassword(newPassword)
    ) {
      updatedErrorMessage.push("Password should be at least 6 characters");
    }
    if (
      !repeatNewPassword ||
      typeof repeatNewPassword !== "string" ||
      newPassword !== repeatNewPassword
    ) {
      updatedErrorMessage.push("New password should match the repeated one");
    }
    if (oldPassword === newPassword) {
      updatedErrorMessage.push("New password should differ from the older one");
    }

    if (updatedErrorMessage.length !== 0) {
      setErrorMessage(updatedErrorMessage);
      return;
    }

    setLoading(true);
    changePassword({
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .then((response) => {
        setIsChanged(true);
        logout(() => navigate("/user/login"));
      })
      .catch((error) => {
        let errorCode = analyzeAxiosError(error);
        if (errorCode === -1 || errorCode >= 500) {
          handleErrorRouting(error, (url: string) => navigate(url));
        } else {
          setErrorMessage(["Operation failed"]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.passwordPage}>
      <>
        <PageTitle
          title="Change password"
          backButton={
            <BsCaretLeftFill
              onClick={() => navigate("/user/preferences")}
              className="ms-auto  d-md-none"
              size={24}
              color={COLOR_BMEDIUMGRAY}
            />
          }
          insidePage
        />
        {loading ? (
          <LaSpinner />
        ) : isChanged ? (
          <div>
            <Alert>Password is successfully changed!</Alert>
          </div>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}
          >
            <InlineErrorMessage errorMessage={errorMessage} />
            <Typography
              align="left"
              component="h1"
              variant="body1"
              sx={{ mb: 2 }}
            >
              <span className={classes.userSpan}>Email : </span> <br /> {email}
            </Typography>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />{" "}
                  Old password
                </>
              }
              type="password"
              id="oldPassword"
              size="small"
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  New password
                </>
              }
              type="password"
              id="newPassword"
              size="small"
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="repeatNewPassword"
              label={
                <>
                  <BiSolidLock
                    color={COLOR_BMEDIUMGRAY}
                    size={22}
                    className="me-2 mb-1"
                  />
                  Repeat new password
                </>
              }
              type="password"
              id="repeatNewPassword"
              size="small"
            />
            <div className={classes.buttonTopMargin}>
              <LaButton
                type="submit"
                onClick={() => handleSubmit}
                laStyle="btn-navy"
              >
                Change password
              </LaButton>
            </div>
          </Box>
        )}
      </>
    </div>
  );
};

export default ChangePassword;
