import LaLink from "../../../components/link/LaLink.component";
import classes from "../Header.module.css";
import { useState, useEffect } from "react";
import AccountMenu from "../accountMenu/AccountMenu";
import { useNavigate } from "react-router-dom";
import { getAuthToken, logout } from "../../../commons/AuthTokenUtils";
import Notifications from "../accountMenu/Notifications";

const LoginMenu = () => {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {!logged ? (
        <nav>
          <ul className={classes.horizontalNav}>
            <li>
              <LaLink
                to={"user/login"}
                children="Log in"
                laStyle="link-solid"
                //onClick={() => auth()}
              />
            </li>
            <li>
              <LaLink
                to="user/signup"
                children="Sign up"
                laStyle="link-solid-yellow"
              />
            </li>
          </ul>
        </nav>
      ) : (
        <div className={classes.account}>
          <div className="d-none d-md-block">
            <Notifications />
          </div>
          <div className="d-none d-md-block">
            <AccountMenu
              logout={() => {
                logout(() => navigate("/"));
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default LoginMenu;
