import State_Type from "../types/State.type";
import Response_Definition from "../types/responses/Response.Definition.type";
import { Response_TermTree } from "../types/responses/Response.TermTree.type";
import Response_UserProfilePublic from "../types/responses/Response.UserProfilePublic.type";

//export const FRONTEND_URL = "http://h3006991.stratoserver.net/";
//export const FRONTEND_URL = "http://localhost:3000/";
export const FRONTEND_URL = "https://lapedia.net/";

export const BACKEND_URL_PREFIX_1 = "api1/";

//export const BACKEND_URL = "http://localhost:1212/" + BACKEND_URL_PREFIX_1;
export const BACKEND_URL = "https://lapedia.net/" + BACKEND_URL_PREFIX_1;

export const FRONTEND_URL_DEFINITION = FRONTEND_URL + "definition/";

export const SPACE = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0";

export const EMAIL_REGEX: RegExp =
  /^[_A-Za-z0-9-+]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$/;

export const HTML_REGEX = /(<([^>]+)>)/gi;

export const ORDER_VOTING_SCORE = "votingScore";
export const ORDER_CREATED_ON = "createdOn";

/**
 * Attributes
 */
export const ATTR_REDIRECT = "redirect";

export const EMPTY_TERM_TREE: Response_TermTree = {
  term: {
    id: "",
    name: "",
    createdOn: 0,
    lastUpdatedOn: 0,
    version: 0,
    state: State_Type.deleted,
    totalDefinitionCount: 0,
  },
  definitions: [],
  nextPage: "",
  totalDefinitionCount: 0,
};

export const EMPTY_USER_PROFILE_PUBLIC: Response_UserProfilePublic = {
  id: "",
  displayName: "",
  firstName: "",
  lastName: "",
  about: "",
  createdOn: 0,
  totalDefinitionCount: 0,
};

export const EMPTY_DEFINITION: Response_Definition = {
  id: "",
  termId: "",
  authorId: "",
  content: "",
  createdOn: 0,
  lastUpdatedOn: 0,
  version: 0,
  state: State_Type.pending,
  votingScore: 0,
  termName: "",
  authorDisplayName: "",
  upvoted: false,
  downvoted: false,
};

/**
 * Public api
 */
export const URL_TOTAL_DEFINITION_COUNT = BACKEND_URL + "totaldefinitioncount/";
export const URL_TERMTREE = BACKEND_URL + "termtree";
export const URL_SEARCH = BACKEND_URL + "search";
export const URL_SEARCH_TERMS = BACKEND_URL + "searchterms";
export const URL_SEARCH_DEFINITIONS = BACKEND_URL + "searchdefinitions";
export const URL_SEARCH_DEFINITIONS_FULL =
  BACKEND_URL + "searchdefinitionsfull";
export const URL_RECENTLY_ADDED_TERMS = BACKEND_URL + "recentlyaddedterms";
export const URL_DEFINITION_ID = BACKEND_URL + "definition/id/";
export const URL_USER_PROFILE_PUBLIC = BACKEND_URL + "userprofilepublic";
export const URL_AUTHOR_DEFINITIONS_PUBLIC =
  BACKEND_URL + "authordefinitionspublic";

/**
 * Auth
 */
export const URL_AUTH = BACKEND_URL + "auth/";
export const URL_AUTH_REGISTER = URL_AUTH + "register/";
export const URL_AUTH_AUTHENTICATE = URL_AUTH + "authenticate/";
export const URL_AUTH_ACTIVATE = URL_AUTH + "activate/";
export const URL_AUTH_VERIFYRESETPASSWORDTOKEN =
  URL_AUTH + "verifyresetpasswordtoken/";
export const URL_AUTH_FORGOT_PASSWORD = URL_AUTH + "forgotpassword/";
export const URL_AUTH_RESET_PASSWORD = URL_AUTH + "resetpassword/";

/**
 * Admin
 */
export const URL_ADMIN = BACKEND_URL + "admin/";
export const URL_ADMIN_TERM_ID = URL_ADMIN + "term/id/";
export const URL_ADMIN_TERM_APPROVE = URL_ADMIN + "term/approve/";
export const URL_ADMIN_TERMTREE = URL_ADMIN + "termtree/";
export const URL_ADMIN_DEFINITION_ID = URL_ADMIN + "definition/id/";
/* */
export const URL_ADMIN_PENDING_TERMS = URL_ADMIN + "pendingterms/";
export const URL_ADMIN_AUTHOR_DEFINITIONS = URL_ADMIN + "authordefinitions/";
export const URL_ADMIN_ALL_TABLES = URL_ADMIN + "alltables/";

/**
 * User
 */
export const URL_USER = BACKEND_URL + "user/";
export const URL_USER_DEFINITION = URL_USER + "definition/";
export const URL_USER_DEFINITION_ID = URL_USER + "definition/id/";
export const URL_USER_DEFINITION_CREATE = URL_USER + "definition/create/";
export const URL_USER_DEFINITIONS = URL_USER + "definitions/";
/* */
export const ACTION_ADD_UPVOTE = "addupvote";
export const ACTION_ADD_DOWNVOTE = "adddownvote";
export const ACTION_DELETE_UPVOTE = "deleteupvote";
export const ACTION_DELETE_DOWNVOTE = "deletedownvote";
/* */
export const URL_USER_PROFILE = URL_USER + "profile/";
export const URL_USER_PROFILE_ID = URL_USER_PROFILE + "id/";
export const URL_USER_PROFILE_CHANGE_PASSWORD =
  URL_USER_PROFILE + "changepassword/";
export const URL_USER_PROFILE_CHANGE_EMAIL = URL_USER_PROFILE + "changeemail/";
/* */
export const URL_USER_NOTIFICATIONS = URL_USER + "notifications/";
export const URL_USER_NOTIFICATIONS_ID = URL_USER_NOTIFICATIONS + "id/";
export const URL_USER_NOTIFICATIONS_MARKASSEEN =
  URL_USER_NOTIFICATIONS + "markasseen/";
/* */
export const URL_ADMIN_NOTIFICATIONS = URL_ADMIN + "notifications/";
export const URL_ADMIN_USERNOTIFICATIONS = URL_ADMIN + "usernotifications/";
export const URL_ADMIN_NOTIFICATIONS_MARKASSEEN =
  URL_ADMIN_NOTIFICATIONS + "markasseen/";
export const URL_ADMIN_USERPROFILE = URL_ADMIN + "userprofile/";
