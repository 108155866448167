import { useEffect, useState } from "react";
import ElementCard from "../../components/elementCard/ElementCard.component";
import NewTermForm from "../newTermForm/NewTermForm";
import Box from "@mui/material/Box";
import PreviousNext from "../../components/previousNext/PreviousNext.component";
import LaModal from "../../components/modal/LaModal.component";
import { getPendingTerms_Admin } from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import Response_PendingTermsPage from "../../types/responses/Response.PendingTerm.type";
import { Response_TermTree } from "../../types/responses/Response.TermTree.type";
import DefinitionInTermTree from "../../definitions/definitionInTermTree/DefinitionInTermTree";
import TotalElementCount from "../../components/totalElementCount/TotalElementCount.component";
import Element_Type from "../../types/Element.type";
import { BsCaretLeftFill } from "react-icons/bs";
import { COLOR_BMEDIUMGRAY } from "../../commons/LaColors";
import { useNavigate } from "react-router";
import { handleErrorRouting } from "../../commons/LaUtils";

const PendingTermCardList = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<Response_PendingTermsPage>();
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  let title = "Pending terms";

  useEffect(() => {
    getData(undefined, undefined);
  }, []);

  // orderedBy param is added match the PreviousNext pattern
  const getData = (orderedBy: string | undefined, page: string | undefined) => {
    setData(undefined);
    getPendingTerms_Admin(page, undefined)
      .then((response) => {
        setData(response.data);
        setNextPage(
          response.data.nextPage === null ? undefined : response.data.nextPage
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      {loading ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : (
        <>
          <PageTitle
            title={title}
            subTitle={
              <TotalElementCount
                countedElement={Element_Type.term}
                totalElementCount={data === undefined ? 0 : data.totalTermCount}
              />
            }
            insidePage={true}
            backButton={
              <BsCaretLeftFill
                onClick={() => navigate("/admin/settings")}
                className="ms-auto  d-md-none"
                size={24}
                color={COLOR_BMEDIUMGRAY}
              />
            }
          />
          {data !== undefined &&
            data.termTrees.map((termTree: Response_TermTree) => {
              return (
                <ElementCard
                  key={termTree.term.id}
                  termId={termTree.term.id}
                  termName={termTree.term.name}
                  termCreatedOn={termTree.term.createdOn}
                  termLastUpdatedOn={termTree.term.lastUpdatedOn}
                  termState={termTree.term.state + ""}
                  definitionElement={
                    <DefinitionInTermTree
                      definition={termTree.definitions[0]}
                      isInline
                    />
                  }
                />
              );
            })}

          <PreviousNext nextPage={nextPage} getData={getData} />

          <LaModal
            show={show}
            onHide={handleClose}
            title="Add a term"
            body={<NewTermForm />}
          />
        </>
      )}
    </div>
  );
};

export default PendingTermCardList;
