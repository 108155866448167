import "./LaSpinner.module.css";
import classes from "./LaSpinner.module.css";

const LaSpinner = (props: { sm?: boolean; variant?: string }) => {
  //let variant = props.variant ? props.variant : "secondary";

  let styling = classes.dotFlashing;
  if (props.sm) {
    styling += " " + classes.dotFlashingSmall;
  }

  return (
    <div className="d-flex justify-content-center ">
      <div className={styling}></div>
    </div>
  );
};

export default LaSpinner;
