import { useEffect, useState } from "react";
import ElementCard from "../../components/elementCard/ElementCard.component";
import NewTermForm from "../newTermForm/NewTermForm";
import Box from "@mui/material/Box";
import Response_TermsPage from "../../types/responses/Response.TermsPage.type";
import Response_Term from "../../types/responses/Response.Term.type";
import classes from "./TermCardList.module.css";
import LaButton from "../../components/button/LaButton.component";
import LaModal from "../../components/modal/LaModal.component";
import { getRecentlyAddedTerms } from "../../commons/Api";
import LaSpinner from "../../components/spinner/LaSpinner.component";
import PageTitle from "../../components/pageTitle/PageTitle.component";
import {
  handleErrorRouting,
  searchParamPageToDatabasePagingState,
} from "../../commons/LaUtils";
import { getAuthToken } from "../../commons/AuthTokenUtils";
import { useLocation, useNavigate } from "react-router";
import { ATTR_REDIRECT } from "../../commons/LaConstants";
import { AiOutlinePlus } from "react-icons/ai";
import { COLOR_BNAVY } from "../../commons/LaColors";
import illustration from "../../assets/illustration.svg";
import PreviousNextWithPaging from "../../components/previousNext/PreviousNextWithPaging.component";
import { useSearchParams } from "react-router-dom";

// TODO-adi: refactor; repeated in search results
export enum TermCardList_Type {
  recentlyAddedTerms,
}

let recentlyAddedPath = "/recentlyadded";

const TermCardList = () => {
  const [searchParams] = useSearchParams();
  let searchParamPage = searchParams.get("page");

  const [show, setShow] = useState(false);
  const [data, setData] = useState<Response_TermsPage>();
  const [isSent, setIsSent] = useState(false);

  const handleClose = () => {
    if (!isSent) {
      const result = window.confirm(
        "Are you sure you want to discard the new term changes?"
      );
      if (result) {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  const handleShow = () => {
    setShow(true);
    setIsSent(false);
  };

  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  let title = "Recently added terms";

  const token = getAuthToken();

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    console.error(event);
    return (event.returnValue = "");
  };

  useEffect(() => {
    if (show) {
      window.addEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, {
        capture: true,
      });
    };
  }, [show]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  });

  useEffect(() => {
    getData(undefined, searchParamPage);
  }, [location]);

  const getData = (
    orderedBy: undefined,
    searchParamPage: string | undefined | null
  ) => {
    setData(undefined);
    getRecentlyAddedTerms(
      searchParamPageToDatabasePagingState(searchParamPage),
      undefined
    )
      .then((response) => {
        setData(response.data);
        setNextPage(
          response.data.nextPage === null ? undefined : response.data.nextPage
        );
      })
      .catch((error) => {
        handleErrorRouting(error, (url: string) => navigate(url));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="row max-width">
      {loading ? (
        <Box>
          <LaSpinner />
        </Box>
      ) : (
        <>
          <div className="col-md-6 ps-md-0">
            <div
              className={`${classes.termCardListSubHeader} flex-column flex-md-row`}
            >
              <PageTitle title={title} />
              <LaButton
                onClick={() => {
                  if (!token) {
                    navigate(
                      "/user/login?" + ATTR_REDIRECT + "=/recentlyAdded"
                    );
                  }
                  handleShow();
                }}
                children={
                  <>
                    <AiOutlinePlus color={COLOR_BNAVY} size={20} /> Add a new
                    term
                  </>
                }
                laStyle="btn-yellow"
              />
            </div>
            {data !== undefined &&
              data.terms.map((term: Response_Term) => {
                return (
                  <ElementCard
                    key={term.id}
                    termId={term.id}
                    termName={term.name}
                    termCreatedOn={term.createdOn}
                    termLastUpdatedOn={term.lastUpdatedOn}
                    termTotalDefinitionCount={term.totalDefinitionCount}
                    termState={term.state + ""}
                  />
                );
              })}

            <PreviousNextWithPaging
              searchParamPage={searchParamPage}
              nextPageExists={nextPage !== undefined}
              url={recentlyAddedPath}
            />

            <LaModal
              show={show}
              onHide={handleClose}
              body={<NewTermForm setIsSent={setIsSent} />}
              backdrop={true}
              title="Add a new term"
            />
          </div>
          <div className="col-md-6 flex-column d-none d-md-flex pl-0">
            <div className={`${classes.illustration} ms-auto`}>
              <img src={illustration} className="px-0 mx-auto" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TermCardList;
