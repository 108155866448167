import classes from "./VerticalMenuSection.module.css";
import LaLink from "../../../components/link/LaLink.component";
import VerticalMenuSectionItems from "../../../types/VerticalMenuSectionItems.type";
import { useLocation } from "react-router";

const VerticalMenuSection = (props: { section: VerticalMenuSectionItems }) => {
  const { pathname } = useLocation();
  return (
    <div className={classes.rightBorder}>
      <ul className={classes.menu}>
        {props.section.items.map((item, index) => {
          return (
            <li key={index} className="mb-2 ">
              <LaLink
                to={item.to}
                children={item.children}
                laStyle="link-profile"
                isActive={pathname === item.to}
              ></LaLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VerticalMenuSection;
