import PageTitle from "../components/pageTitle/PageTitle.component";
import LaLink from "../components/link/LaLink.component";
import { useEffect } from "react";

const LegalNotice = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <>
      <div className="max-width row-sm">
        <PageTitle title="Legal notice" />
        <p>
          <b>Information in accordance to § 5 TMG</b>
          <br />
          Dr. Oday Jubran - Founder and Managing Director
          <br />
          Am Hasensprung 8a
          <br />
          60437 Frankfurt
          <br />
          Germany
          <br />
          <br />
          <b>Represented by</b>
          <br />
          Dr. Oday Jubran
          <br />
          <br />
          <b>Responsible for content according to § 55 Abs. 2 RStV</b>
          <br />
          Dr. Oday Jubran
          <br />
          <br />
          <b>Contact</b>
          <br />
          Telephone: +49 (0) 69 15344178
          <br />
          Email:{" "}
          <LaLink to={"mailto:contact@lapedia.net"}>
            contact@lapedia.net.
          </LaLink>{" "}
          <br />
          <br />
          <b>Disclaimer</b>
          <br />
          <br />
          <p>
            <b>Liability for content</b>
            <br />
            The contents of our pages have been created with the utmost care.
            However, we cannot guarantee the accuracy, completeness, and
            timeliness of the content.
          </p>
          <p>
            <b>Liability for links</b>
            <br />
            Our offer contains links to external websites of third parties, on
            whose contents we have no influence. Therefore, we cannot assume any
            liability for these external contents.{" "}
          </p>
          <p>
            <b>Copyright</b>
            <br />
            The contents and works created by the site operators on these pages
            are subject to German copyright law. The duplication, processing,
            distribution, and any kind of exploitation outside the limits of
            copyright require the written consent of the respective author or
            creator.
          </p>
          <p>
            <b>Data protection</b>
            <br />{" "}
            <LaLink to={"https://lapedia.net/privacypolicy"}>
              Privacy Policy
            </LaLink>{" "}
          </p>
        </p>
        <br />
      </div>
    </>
  );
};

export default LegalNotice;
